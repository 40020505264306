/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { Form, Button, DatePicker } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../../common/antd-form-components/AntdTextField';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import successNotification from '../../../utils/successNotification';
import errorNotification from '../../../utils/errorNotification';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import PosCashPaymentsContext from '../../../contexts/pos-cash-payments/PosCashPaymentsContext';
import moment from 'moment';
import getAccountsListLeafApi from '../../../apis/accounting-apis/accounting-details-apis/getAccountsListLeafApi';
import getAllClientsApi from '../../../apis/clients-apis/getAllClientsApi';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import createPaymentsProducts from '../../../apis/cash-payments-products/createPaymentsProducts';
import { useTranslation } from 'react-i18next';

const TableForm = ({ departments }) => {
  const { t } = useTranslation();

  const { user } = useContext(UserContext);
  const {
    setIsLoading,
    setModalOpenedCashIn,
    setFetchCount,
    selectedCash,
    isSubmittingForm,
    setIsSubmittingForm
  } = useContext(PosCashPaymentsContext);
  const schema = Yup.object().shape({
    // title: Yup.string().required('ادخل ملاحظات الدفع '),
    account_id: Yup.string().required(t('Inventory.CashIn.chooseAccount')),
    payment_method_id: Yup.string().optional('اختر طريقة الدفع '),
    user_id: Yup.string().required(t('Inventory.CashIn.chooseClient')),
    username: Yup.string().required(t('Inventory.CashIn.enterName')),
    price_word: Yup.string().required(t('Inventory.CashIn.enterLettersAmount')),
    // department_id: Yup.string().required('ادخل القسم'),
    date: Yup.mixed().required(t('Inventory.CashIn.chooseDueDate')),
    amount: Yup.string()
      .required(t('Inventory.CashIn.enterAmount'))
      .matches(/^(\d+)?(\.\d+)?$/, t('Inventory.CashIn.onlyNumbersAllowed'))
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      date: selectedCash ? moment(selectedCash?.date) : null,
      note: '',
      amount: '',
      // department_id: '',
      user_id: '',
      payment_method_id: '',
      account_id: '',
      price_word: '',
      username: ''
    }
  });
  // handle initial values
  useEffect(() => {
    if (selectedCash) {
      if (selectedCash?.date) {
        setValue('date', moment(selectedCash.date).format('YYYY-MM-DD'));
      }
      if (selectedCash?.username_reciept) {
        setValue('username_reciept', selectedCash.username_reciept);
      }
      if (selectedCash?.note) {
        setValue('note', selectedCash.note);
      }
      if (selectedCash?.price_word) {
        setValue('price_word', selectedCash.price_word);
      }
      if (selectedCash?.amount) {
        setValue('amount', selectedCash.amount);
      }
      // if (selectedCash?.department) {
      //   setValue('department_id', selectedCash?.department?.id);
      // }
      if (selectedCash?.user_id) {
        setValue('user_id', selectedCash.user_id);
      }
      if (selectedCash?.payment_method_id) {
        setValue('payment_method_id', selectedCash.payment_method_id);
      }
      if (selectedCash?.account_id) {
        setValue('account_id', selectedCash.account_id);
      }
    }
  }, [selectedCash]);
  const systemSettings = useSystemSettings();
  const payments = systemSettings?.invoiceSetting?.payment_methods_objects;
  const [accountsList, setAccountsLists] = useState();
  const [allClients, setAllClients] = useState();
  useEffect(() => {
    let api = getAccountsListLeafApi(user?.token, user?.client_id);
    customApiRequest(api, (res) => {
      if (res?.data?.accounts) {
        setAccountsLists(res?.data?.accounts);
      }
    });
  }, []);

  useEffect(() => {
    customApiRequest(
      getAllClientsApi(user?.token),
      (res) => {
        if (res?.status === 200 && res?.data?.data?.length >= 0) {
          const data = res.data.data;
          setAllClients(data);
        }
      },
      (error) => {}
    );
  }, []);

  const customApiRequest = useCustomApiRequest();
  const onSubmit = async (data) => {
    const mappedData = new FormData();
    if (data.amount) mappedData.append('amount', data.amount);
    if (data.note) mappedData.append('note', data.note);
    if (data.date)
      mappedData.append('date', moment(data.date).format('YYYY-MM-DD'));
    // if (data.department_id)
    //   mappedData.append('department_id', data.department_id);
    if (data.user_id) mappedData.append('user_id', data.user_id);
    if (data.payment_method_id)
      mappedData.append('payment_method_id', data.payment_method_id);
    if (data.account_id) mappedData.append('account_id', data.account_id);
    if (data.price_word) mappedData.append('price_word', data.price_word);
    mappedData.append('type', 1);
    // receipt = 1 سند قبض
    // expense = 2 سند صرف
    // mappedData.type = 2;

    setIsSubmittingForm(true);
    setIsLoading(true);
    if (selectedCash) {
      mappedData.append('id', selectedCash.id);
      customApiRequest(
        createPaymentsProducts(user?.token, mappedData, true),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpenedCashIn(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Inventory.CashIn.addedSuccessfully'),
              message: t('Inventory.CashIn.cashInModifiedSuccessfully')
            });
          } else {
            errorNotification({
              title: t('Inventory.CashIn.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Inventory.CashIn.pleaseTryLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: t('Inventory.CashIn.errorOccurred'),
            message:
              error?.response?.data?.message ||
              t('Inventory.CashIn.pleaseTryLater')
          });
        }
      );
    } else {
      customApiRequest(
        createPaymentsProducts(user?.token, mappedData),
        (res) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            setModalOpenedCashIn(false);
            setFetchCount((prevState) => prevState + 1);
            successNotification({
              title: t('Inventory.CashIn.addedSuccessfully'),
              message: t('Inventory.CashIn.cashInSuccessfully')
            });
          } else {
            errorNotification({
              title: t('Inventory.CashIn.errorOccurred'),
              message: res?.data?.message
                ? res.data.message
                : t('Inventory.CashIn.pleaseTryLater')
            });
          }
        },
        (error) => {
          setIsSubmittingForm(false);
          setIsLoading(false);
          errorNotification({
            title: t('Inventory.CashIn.errorOccurred'),
            message:
              error?.response?.data?.message ||
              t('Inventory.CashIn.pleaseTryLater')
          });
        }
      );
    }
  };

  const [form] = Form.useForm();
  const { field } = useController({
    name: 'date',
    control
  });
  return (
    <Form
      className="custom-shared-form ingredients-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Inventory.CashIn.amount')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="amount"
              type="text"
              placeholder={t('Inventory.CashIn.amountPlaceholder')}
              errorMsg={errors?.amount && errors.amount.message}
              validateStatus={errors?.amount ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Inventory.CashIn.enterLettersAmount')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="price_word"
              type="text"
              placeholder={t('Inventory.CashIn.enterLettersAmountPlaceholder')}
              errorMsg={errors?.price_word && errors.price_word.message}
              validateStatus={errors?.price_word ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="text-field-label-wrap">
          <p className="label-p">{t('Inventory.CashIn.enterName')}</p>
          <div className="text-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="username"
              type="text"
              placeholder={t('Inventory.CashIn.enterNamePlaceholder')}
              errorMsg={errors?.username && errors.username.message}
              validateStatus={errors?.username ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="text-field-label-wrap">
          <p className="label-p">{t('Inventory.CashIn.notes')}</p>
          <div className="text-field-wrap">
            <AntdTextarea
              className="form-text-area"
              name="note"
              rows={4}
              placeholder={t('Inventory.CashIn.notesPlaceholder')}
              errorMsg={errors?.note?.message}
              validateStatus={errors?.note ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="date-picker-wrap">
          <p className="label-p">{t('Inventory.CashIn.dueDate')}</p>
          <DatePicker
            name="date"
            // format="YYYY-MM-DD"
            size="large"
            {...field}
            // control={control}
            defaultValue={selectedCash?.date && moment(selectedCash?.date)}
            // bordered={false}
            setValue={setValue}
            placeholder={t('Inventory.CashIn.dueDatePlaceholder')}
            className="receipt-date-picker"
            formClassName="create-receipt-form"
          />
          {errors?.date && <p className="error-p">{errors.date.message}</p>}
        </div>

        <div className="select-label-wrap">
          <p className="label-p">{t('Inventory.CashIn.chooseAccountType')}</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="account_id"
              errorMsg={errors?.account_id?.message}
              validateStatus={errors?.account_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder={t('Inventory.CashIn.chooseAccountType')}
              options={
                accountsList?.length > 0 &&
                accountsList.map((acc) => ({
                  title: acc.holder,
                  value: acc.id
                }))
              }
              formClassName="ingredients-form"
            />
          </div>
        </div>
        <div className="select-label-wrap">
          <p className="label-p">{t('Inventory.CashIn.chooseClient')}</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="user_id"
              errorMsg={errors?.user_id?.message}
              validateStatus={errors?.user_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder={t('Inventory.CashIn.chooseClient')}
              options={
                allClients?.length > 0 &&
                allClients.map((client) => ({
                  title: client.name,
                  value: client.id
                }))
              }
              formClassName="ingredients-form"
            />
          </div>
        </div>
        <div className="select-label-wrap">
          <p className="label-p">{t('Inventory.CashIn.payWay')}</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="payment_method_id"
              errorMsg={errors?.payment_method_id?.message}
              validateStatus={errors?.payment_method_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder={t('Inventory.CashIn.payWay')}
              options={
                payments?.length > 0 &&
                payments.map((pay) => ({
                  title: pay.name,
                  value: pay.id
                }))
              }
              formClassName="ingredients-form"
            />
          </div>
        </div>
        {/* <div className="select-label-wrap">
          <p className="label-p">القسم</p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="department_id"
              errorMsg={errors?.department_id?.message}
              validateStatus={errors?.department_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="القسم"
              options={
                departments?.length > 0 &&
                departments.map((department) => ({
                  title: department.name,
                  value: department.id
                }))
              }
              formClassName="ingredients-form"
            />
          </div>
        </div> */}

        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          loading={isSubmittingForm}
        >
          {selectedCash
            ? t('Inventory.CashIn.edit')
            : t('Inventory.CashIn.add')}
        </Button>
      </div>
    </Form>
  );
};

export default TableForm;
