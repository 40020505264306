import { DatePicker, Select } from 'antd';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import getAllIncomingPurchaseInvoicesApi from '../../../apis/store-apis/incoming-purchas-invoices-apis/getAllIncomingPurchaseInvoicesApi';
import excelImg from '../../../assets/imgs/icons/excel-btn.png';
import CalendarIcon from '../../../common/icons/CalendarIcon';
import SelectDropDown from '../../../common/icons/SelectDropDown';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useSuppliers from '../../../custom-hooks/useSuppliers';
// import Workbook from 'react-excel-workbook';
import debounce from 'lodash.debounce';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import SearchIcon from '../../../common/icons/SearchIcon';
import useClients from '../../../custom-hooks/useClients';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import useUsers from '../../../custom-hooks/useUsers';
import useWorkPlaces from '../../../custom-hooks/useWorkPlaces';
// import invoiceFilterTypes from '../../../invoiceFilterTypes';
import userRoles from '../../../userRoles';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import SyncSallaApi from '../../../apis/fatoorahStore-api/SyncSallaApi';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;
const invoiceFilterTypes = {
  outgoingInvoice: 3, // sale invoice
  incommingInvoice: 2 // buy invoice
};

const TableFilterProducts = ({
  allFetchedReceipts,
  selectedPicker,
  setSelectedPicker
}) => {
  const { t } = useTranslation();
  const receiptTypes = [
    {
      title: t('Invoices.saleInvoices'),
      value: '1'
    },
    {
      title: t('Invoices.returnInvoices'),
      value: '2'
    }
  ];
  const receiptContent = [
    {
      title: t('Invoices.productsInvoices'),
      value: '1'
    }
    // {
    //   title: 'فواتير مكونات منتجات',
    //   value: '2'
    // }
  ];
  const { user } = useContext(UserContext);
  const systemSettings = useSystemSettings();
  const allDelegateUsers = useUsers(userRoles?.delegate);
  const { pathname } = useLocation();
  const user_id_from_payment = pathname?.split('/')[3];
  const { Option } = Select;
  //   const [selectedPicker, setSelectedPicker] = React.useState(null);
  const [filterValues, setFilterValues] = React.useState({
    invoiceNumber: '',
    id: '',
    client_id: null,
    invoice_type: 2,
    supplier_id: null,
    type: null,
    detailsType: null,
    admin_id: '',
    from: '',
    to: ''
  });
  const { allSuppliers } = useSuppliers();
  const { allClients } = useClients();
  const allWorkPlaces = useWorkPlaces();

  const {
    setAllFetchedReceipts,
    setIsLoading,
    tableFilter,
    setTableFilter,
    setTablePagination,
    setAllReceiptsData
  } = useContext(OutgoingPurchaseInvoiceContext);

  const handlePickerChange = (value, string) => {
    setSelectedPicker({
      moments: value,
      strings: string
    });
  };
  React.useEffect(() => {
    if (selectedPicker?.strings?.length > 0) {
      setTableFilter((prev) => ({
        ...prev,
        from: selectedPicker.strings[0],
        to: selectedPicker.strings[1]
      }));
    }
  }, [selectedPicker?.strings]);

  // const handleSearchFilter = (e) => {
  //   debouncedChangeHandler(e);
  // };

  const [is_fetched , setIsFetched] = useState(false);
  const debouncedChangeHandler = useCallback(
    debounce(async (e) => {
      setIsFetched(true)
      setTableFilter((prevState) => ({
        ...prevState,
        invoiceNumber: e.target.value
      }));
    }, 500),
    []
  );

  async function handleChange(filterType, value) {
    setTableFilter((prevState) => {
      if (filterType === 'id') {
        return {
          ...prevState,
          id: value.target.value
        };
      } else if (filterType === 'client') {
        return {
          ...prevState,
          user_id: value
        };
      } else if (filterType === 'supplier') {
        return {
          ...prevState,
          supplier_id: value
        };
      } else if (filterType === 'pay_id') {
        return {
          ...prevState,
          pay_id: value
        };
      } else if (filterType === 'type') {
        return {
          ...prevState,
          type: value
        };
      } else if (filterType === 'detailsType') {
        return {
          ...prevState,
          detailsType: value
        };
      } else if (filterType === 'admin_id') {
        return {
          ...prevState,
          admin_id: value
        };
      }
      // else if (filterType === 'client') {
      //   return {
      //     ...prevState,
      //     client_id: value
      //   };
      // }
    });
  }
  useEffect(() => {
    setIsLoading(true);
    if (is_fetched) {
      customApiRequest(
        getAllIncomingPurchaseInvoicesApi(user?.token, {
          invoice_type: 2,
          ...tableFilter,
          page: 1,
          user_id_from_payment: user_id_from_payment
        }),
        (res) => {
          setIsLoading(false);
          if (res?.status === 200 && res?.data?.data) {
            const data = res.data.data.invoices.data;
            setAllFetchedReceipts(data);
            setAllReceiptsData(res.data.data)
            setTablePagination(res?.data?.data?.invoices?.pagination);
          } else {
          }
        },
        (error) => {
          setIsLoading(false);
        }
      );
    }
  }, [tableFilter]);

  const customApiRequest = useCustomApiRequest();
  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    customApiRequest(
      getAllIncomingPurchaseInvoicesApi(user?.token, {
        invoice_type: 2,
        ...tableFilter,
        page: 1,
        user_id_from_payment: user_id_from_payment
      }),
      (res) => {
        setIsLoading(false);
        if (res?.status === 200 && res?.data?.data) {
          const data = res.data.data.invoices.data;
          setAllFetchedReceipts(data);
          setAllReceiptsData(res.data.data)
          setTablePagination(res?.data?.data?.invoices?.pagination);
        } else {
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }

  const syncSallaAuth = async () => {
    try {
      const res = await SyncSallaApi(
        user?.token,
        systemSettings?.sallaToken,
        'orders'
      );
      if (res?.status === 200 && res?.data?.status === 1) {
        // setIsLoading(false);
        // setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Messages.addedSuccessfully'),
          message:
            t('Messages.ordersshavebeenlinkedtosallasuccessfully') ||
            res.data.message
        });
      } else {
        // setIsLoading(false);
        errorNotification({
          title: t('Messages.ErrorOccurred'),
          message: res?.data?.message
            ? res.data.messa
            : t('Messages.TryAgainLater')
        });
      }
    } catch (error) {
      // setIsLoading(false);
      errorNotification({
        title: t('Messages.ErrorOccurred'),
        message: t('Messages.TryAgainLater')
      });
    }
  };

  return (
    <div className="custom-table-fitler warehouse-table-filter">
      <div className="form-excel-export-wrap">
        <form
          className="filter-form receipt-filter-form"
          onSubmit={handleSubmit}
        >
          <div className="search-label-wrapper">
            <p
              style={{
                marginBottom: 5
              }}
            >
              {t('Invoices.InvoiceNumber')}
            </p>
            <div className="table-search" style={{ width: '310px' }}>
              <label>
                <SearchIcon />
                <input
                  placeholder={t('Invoices.EnterSerialInvoiceNumber')}
                  type="text"
                  name="invoiceNumber"
                  onChange={(e) => debouncedChangeHandler(e)}
                />
              </label>
            </div>
          </div>

          {/* <label className="receipt-filter-option-wrap">
            <span className="select-title">إســم المورد</span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('supplier', v)}
              suffixIcon={<SelectDropDown />}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={null}>جميع الموردين</Option>
              {allSuppliers?.length > 0 &&
                allSuppliers.map((item) => (
                  <Option key={item.id} value={String(item.id)}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </label> */}
          <label className="receipt-filter-option-wrap">
            <span className="select-title">{t('Invoices.CustomerName')} </span>
            <Select
              defaultValue={user_id_from_payment || null}
              style={{ width: 172 }}  
              onChange={(v) => handleChange('client', v)}
              suffixIcon={<SelectDropDown />}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={null}>{t('Invoices.AllCustomers')}</Option>
              {allClients?.length > 0 &&
                allClients.map((item) => (
                  <Option key={item.id} value={String(item.id)}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </label>
          {/* الفرع اسم الموظف واسم */}
          {/* <label className="receipt-filter-option-wrap">
            <span className="select-title">إســم  </span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('client', v)}
              suffixIcon={<SelectDropDown />}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={null}>{t('Invoices.allEmployees')}</Option>
              {allClients?.length > 0 &&
                allClients.map((item) => (
                  <Option key={item.id} value={String(item.id)}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </label>
          <label className="receipt-filter-option-wrap">
            <span className="select-title">إســم الفرع </span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('client', v)}
              suffixIcon={<SelectDropDown />}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={null}>جميع الافراد</Option>
              {allClients?.length > 0 &&
                allClients.map((item) => (
                  <Option key={item.id} value={String(item.id)}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </label> */}
          {user?.role !== userRoles?.delegate && (
            <label className="receipt-filter-option-wrap">
              <span className="select-title">{t('Invoices.EmployeeName')}</span>
              <Select
                defaultValue={null}
                style={{ width: 172 }}
                onChange={(v) => handleChange('admin_id', v)}
                suffixIcon={<SelectDropDown />}
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={null}>{t('Invoices.allEmployees')}</Option>
                {allDelegateUsers?.length > 0 &&
                  allDelegateUsers.map((item) => (
                    <Option key={item.id} value={String(item.id)}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            </label>
          )}

          {/* <label className="receipt-filter-option-wrap">
            <span className="select-title">إســم الفرع</span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('admin_id', v)}
              suffixIcon={<SelectDropDown />}
              showSearch={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={null}>جميع الفروع</Option>
              {allWorkPlaces?.length > 0 &&
                allWorkPlaces.map((item) => (
                  <Option key={item.id} value={String(item.id)}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </label> */}

          <label className="receipt-filter-option-wrap">
            <span className="select-title">{t('Invoices.InvoiceType')}</span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('type', v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>{t('Invoices.allInvoices')}</Option>
              {receiptTypes.map((role, index) => (
                <Option key={index} value={role.value}>
                  {role.title}
                </Option>
              ))}
            </Select>
          </label>
          <label className="receipt-filter-option-wrap">
            <span className="select-title">{t('Invoices.paymentType')}</span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('pay_id', v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>{t('Invoices.allPaymentsTypes')}</Option>
              <Option value={1}>{t('Invoices.cash')}</Option>
              <Option value={2}>{t('Invoices.postpaid')}</Option>
              <Option value={3}>{t('Invoices.visa')}</Option>
              <Option value={5}>{t('Invoices.bankTransfer')}</Option>
              <Option value={6}>{t('Invoices.multiplePayment')}</Option>
              {/* {receiptTypes.map((role, index) => (
                <Option key={index} value={role.value}>
                  {role.title}
                </Option>
              ))} */}
            </Select>
          </label>
          {/* <label className="receipt-filter-option-wrap">
            <span className="select-title">محتوى الفاتورة</span>
            <Select
              defaultValue={null}
              style={{ width: 172 }}
              onChange={(v) => handleChange('detailsType', v)}
              suffixIcon={<SelectDropDown />}
            >
              <Option value={null}>{t('Invoices.allInvoices')}</Option>
              {receiptContent.map((role, index) => (
                <Option key={index} value={role.value}>
                  {role.title}
                </Option>
              ))}
            </Select>
          </label> */}
          <div className="range-picker-wrap">
            <RangePicker
              value={selectedPicker?.moments && selectedPicker.moments}
              onChange={handlePickerChange}
              // format="YYYY-MM-DD"
              className={`filter-range-picker`}
              allowClear={true}
              suffixIcon={<CalendarIcon />}
              placeholder={[t('Invoices.dateFrom'), t('Invoices.dateTo')]}
            />
          </div>
          {/* <button className="filter-submit-btn" type="submit">
            بحث
          </button> */}
          <div className="excel-btn-wrap">
            <a
              href={`${
                process.env.REACT_APP_BASE_URL
              }/exportExcelInvoice?client_id=${systemSettings?.id || ''}
                &user_id=${tableFilter?.user_id || ''}&type=${
                invoiceFilterTypes?.outgoingInvoice || ''
              }&typeN=${tableFilter?.type || ''}&manualNum=${
                tableFilter?.manualNum || ''
              }&supplier_id=${tableFilter?.supplier_id || ''}&detailsType=${
                tableFilter?.detailsType || ''
              }&from=${tableFilter?.from || ''}&to=${
                tableFilter?.to || ''
              }&admin_id=${tableFilter?.admin_id || ''}&paymentType=${
                tableFilter?.pay_id || ''
              }&page=${tableFilter?.page || '1'}&invoiceNumber=${
                tableFilter?.invoiceNumber
              }`}
              className="excel-export-btn"
            >
              <img
                style={{ height: '42px' }}
                src={excelImg}
                alt="export to excel"
              />
            </a>
          </div>
          {/* {systemSettings?.sallaToken && (
            <div
              className="preview-download-upload add-btn"
              onClick={() => syncSallaAuth()}
            >
              <span className="text-span">{t('Invoices.TieWithSalla')}</span>
            </div>
          )} */}
        </form>
        {/* <div className="excel-btn-wrap">
          <Workbook
            filename="outgoing_invoice.xlsx"
            element={
              <div className="excel-export-btn">
                <img
                  style={{ height: '42px' }}
                  src={excelImg}
                  alt="export to excel"
                />
              </div>
            }
          >
            <Workbook.Sheet
              data={
                allFetchedReceipts?.invoices ? allFetchedReceipts?.invoices : []
              }
              name="Sheet A"
            >
              <Workbook.Column label="مسلسل" value="invoiceNumber" />
              <Workbook.Column label="رقم الفاتورة" value="manualNum" />
              <Workbook.Column
                label="المورد"
                value={(row) => row.supplier.name}
              />
              <Workbook.Column
                label="تاريخ الاستحقاق"
                value={(row) => row.dueDate}
              />
              <Workbook.Column
                label="السعر الإجمالى"
                value={(row) => row.totalPrice}
              />
            </Workbook.Sheet>
          </Workbook>
        </div> */}
      </div>
    </div>
  );
};

export default TableFilterProducts;
