/* eslint-disable eqeqeq */
import { Button, notification } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import ReactHookFormContext from '../../contexts/react-hook-form-context/ReactHookFormContext';
// import qrCodeImg from '../../assets/imgs/qr-code-img.png';
import moment from 'moment';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AddToCartIcon from '../../common/icons/AddToCartIcon';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import ClientsPosContext from '../../contexts/clients-context-pos/ClientsPosContext';
import UserContext from '../../contexts/user-context/UserProvider';
import usePosSettings from '../../custom-hooks/usePosSettings';
import errorNotification from '../../utils/errorNotification';
import Links from '../app/Links';
import SharedLayoutBtns from '../app/pos-section/SharedLayoutBtns';
import './PosInvoice.scss';
import invoiceSubmitFunction from './invoiceSubmitFunction';

const PosInvoice = React.forwardRef((props, ref) => {
  const routerLinks = Links();
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const history = useHistory();
  const [currentLang, setCurrentLang] = useState();
  const date = new Date();
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);
  const { step, setStep, isLastStep, childrenArray } =
    useContext(ReactHookFormContext);
  const { user } = useContext(UserContext);
  const {
    resetPos,
    selectedInvoice,
    selectedProducts,
    invoiceDiscountType,
    invoiceDiscountValue,
    invoiceExtraPrices,
    invoicePayType,
    invoiceNote,
    invoiceMultiplePaymentVisa,
    invoiceMultiplePaymentCash,
    invoiceDeferredPayment,
    setInvoiceDeferredPayment,
    //
    // invoiceDiscountPrice,
    // invoiceTotalProductsPrice,
    invoiceTotalProductsNetPrice,
    invoiceTotalProductsPriceAfterDiscount,
    invoiceTotalProductsPriceAfterTax,
    invoiceTotalAfterExtraPrices,
    setInvoicePayType,
    // invoiceTotalPriceAfterDiscount,
    // invoiceTotalPriceAfterTax,
    //
    setLaterInvoiceModalOpened,
    laterInvoiceName,
    laterInvoicePhone,
    laterInvoiceAddress,
    laterInvoiceDueDate,
    setInvoiceBankPayment,
    invoiceBankPayment,
    isSubmittingOrder,
    setIsSubmittingOrder,
    successOrder,
    setSucessOrder,
    systemSettings,
    totalTax
  } = useContext(PosInvoiceContext);

  const { selectedClientId } = useContext(ClientsPosContext);
  // taxtype = 1 => price without tax
  // taxtype = 2 => price with tax
  const productTaxTypes = {
    withTax: '2',
    withoutTax: '1'
  };
  const getProductSalePrice = (p) => {
    if (p?.taxType == productTaxTypes?.withoutTax) {
      if (p?.salePrice) {
        return parseFloat(p?.salePrice);
      } else {
        return 0;
      }
    } else if (p?.taxType == productTaxTypes?.withTax) {
      if (p?.salePriceWithTax) {
        return parseFloat(p?.salePriceWithTax);
      } else {
        return 0;
      }
    }
  };
  const posSettings = usePosSettings();
  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message,
      description
    });
  };

  const handleInvoiceSubmit = async () => {
    let valid = null;
    const requestData = {};
    if (selectedClientId) {
      requestData.user_id = selectedClientId;
    }
    if (selectedProducts?.length > 0) {
      const mappedProducts = selectedProducts.map((p) => ({
        id: p?.id,
        quantity: p.productInCartQuantity,
        discountType: p.productDiscountType,
        discountValue: p.productDiscountValue,
        tax: parseFloat(p?.tax) > 0 ? p.tax : 0,
        // tax: systemSettings?.invoiceSetting?.tax,
        materials:
          p.productIngredients?.length > 0
            ? p.productIngredients
                .map((ing) => {
                  if (
                    !isNaN(parseFloat(ing.ingredientInCartQuantity)) &&
                    ing.checked
                    // parseFloat(ing.ingredientInCartQuantity) >= 0
                  )
                    return {
                      id: ing.id,
                      quantity: ing.ingredientInCartQuantity
                    };
                  else return null;
                })
                .filter((o) => o)
            : null
      }));
      requestData.products = JSON.stringify(mappedProducts);
    }

    if (invoiceDiscountType) {
      requestData.discountType = invoiceDiscountType;
    }

    if (invoiceDiscountValue) {
      requestData.discountValue = invoiceDiscountValue;
    }

    if (invoiceNote) {
      requestData.note = invoiceNote;
    }

    if (invoicePayType?.value) {
      requestData.paymentType = invoicePayType?.value;
      setInvoicePayType((prv) => ({ value: prv?.value, errors: false }));
    } else {
      setInvoicePayType((prv) => ({ ...prv, errors: true }));
      valid = false;
    }
    if (invoicePayType?.value == 1) {
      valid = true;
    } else {
      valid = false;
    }
    if (invoicePayType?.value == 2 && !invoiceDeferredPayment?.errors) {
      if (
        invoiceDeferredPayment.invoice_due_date &&
        !invoiceDeferredPayment?.errors
      ) {
        requestData.deferred = invoiceTotalAfterExtraPrices;
        requestData.invoice_due_date = invoiceDeferredPayment.invoice_due_date;
        setInvoiceDeferredPayment((prv) => ({ ...prv, errors: false }));
        valid = true;
      } else {
        setInvoiceDeferredPayment((prv) => ({
          ...prv,
          errors: true
        }));
        valid = false;
      }

      if (
        invoiceDeferredPayment.deferred_invoice_client &&
        !invoiceDeferredPayment.deferred_invoice_client_errors
      ) {
        requestData.user_id = invoiceDeferredPayment.deferred_invoice_client;
        setInvoiceDeferredPayment((prv) => ({
          ...prv,
          deferred_invoice_client_errors: false
        }));
        valid = true;
      } else {
        setInvoiceDeferredPayment((prv) => ({
          ...prv,
          deferred_invoice_client_errors: true
        }));
        valid = false;
      }
      requestData.first_pay_type = invoiceDeferredPayment.first_pay_type;
      if (invoiceDeferredPayment?.first_pay_type) {
        requestData.first_paid = invoiceDeferredPayment.first_paid
          ? invoiceDeferredPayment.first_paid
          : 0;
        if (invoiceDeferredPayment.first_pay_img?.length) {
          requestData.first_pay_img = invoiceDeferredPayment.first_pay_img[0];
        }
      }
    }
    if (invoicePayType?.value == 3) {
      valid = true;
    }
    if (invoicePayType?.value == 5) {
      requestData.bank = invoiceTotalAfterExtraPrices;
      if (invoiceBankPayment?.file?.length) {
        requestData.bank_image = invoiceBankPayment?.file[0];
      }
      valid = true;
    }
    if (invoicePayType?.value == 6) {
      if (
        !invoiceMultiplePaymentCash?.errors?.message &&
        !invoiceMultiplePaymentVisa?.errors?.message &&
        invoiceMultiplePaymentVisa.value &&
        invoiceMultiplePaymentCash.value
      ) {
        requestData.visa = invoiceMultiplePaymentVisa?.value;
        requestData.cash = invoiceMultiplePaymentCash?.value;
        requestData.totalInvoicePrice = invoiceTotalAfterExtraPrices;
        valid = true;
      } else {
        valid = false;
      }
    }

    if (invoiceExtraPrices?.length > 0) {
      requestData.addPrices = JSON.stringify(invoiceExtraPrices);
    }

    requestData.type = 1; // => sale invoice type
    if (laterInvoiceName && laterInvoiceDueDate) {
      requestData.type = 3; // resversion invoice
    }

    if (laterInvoiceName) {
      requestData.name = laterInvoiceName;
    }
    if (laterInvoicePhone) {
      requestData.phone = laterInvoicePhone;
    }
    if (laterInvoiceAddress) {
      requestData.address = laterInvoiceAddress;
    }
    if (laterInvoiceDueDate)
      requestData.dueDate = moment(laterInvoiceDueDate).format('MM/DD/YYYY');
    //
    if (selectedInvoice) {
      requestData.type = 2; // => return invoice type
      requestData.parentInvoice = selectedInvoice.id;
    }
    !invoicePayType.value &&
      errorNotification({
        title: t('Pos.Main.errorOccurred'),
        message: t('Pos.Main.pleaseEnterPaymentType')
      });
    const formData = new FormData();

    for (let key in requestData) {
      formData.append(key, requestData[key]);
    }
    let validQuantity = true;
    // check for products before submitting order
    if (!requestData?.products || requestData?.products?.length === 0) {
      valid = false;
      openNotificationWithIcon(
        'error',
        t('Pos.Main.errorOccurred'),
        t('Pos.Main.pleaseChooseTheProductsFirst')
      );
    } else {
      const requestDataProducts = JSON.parse(requestData.products);
      if (!selectedInvoice) {
        // create new order
        for (let obj of requestDataProducts) {
          if (!obj.quantity || obj.quantity <= 0) {
            // check for product quantity
            valid = false;
            openNotificationWithIcon(
              'error',
              t('Pos.Main.errorOccurred'),
              t('Pos.Main.productQuantityIncorrect')
            );
          } else {
            if (obj.materials?.length > 0) {
              // check for product ingredients quantity
              for (let i of obj.materials) {
                if (!i.quantity || parseFloat(i.quantity) <= 0) {
                  valid = false;
                  openNotificationWithIcon(
                    'error',
                    t('Pos.Main.errorOccurred'),
                    t('Pos.Main.productQuantityIncorrect')
                  );
                }
              }
            }
          }
        }

        if (valid) {
          invoiceSubmitFunction(
            user,
            formData,
            setIsSubmittingOrder,
            setSucessOrder
          );
        }
      } else {
        // in case of returning order
        for (let obj of requestDataProducts) {
          if (isNaN(obj.quantity) || parseFloat(obj.quantity) < 0) {
            // check for product quantity
            validQuantity = false;
            valid = false;
            openNotificationWithIcon(
              'error',
              t('Pos.Main.errorOccurred'),
              t('Pos.Main.productQuantityIncorrect')
            );
          } else {
            if (obj.materials?.length > 0) {
              // check for product ingredients quantity
              for (let i of obj.materials) {
                if (isNaN(i.quantity) || parseFloat(i.quantity) < 0) {
                  validQuantity = false;
                  valid = false;
                }
              }
            }
          }
        }
        if (!validQuantity)
          openNotificationWithIcon(
            'error',
            t('Pos.Main.errorOccurred'),
            t('Pos.Main.productQuantityIncorrect')
          );
        if (valid) {
          invoiceSubmitFunction(
            user,
            formData,
            setIsSubmittingOrder,
            setSucessOrder
          );
        }
      }
    }
    // end check for product before submitting order
  };

  const renderInvoiceProduct = (p) => {
    if (p.productIngredients?.length > 0) {
      return (
        <tr key={p.id} className="invoice-product-li">
          <td className="invoice-table-cell product-name-cell">
            {p.name ? p.name : '----'}
            {p.productIngredients.map((ing) =>
              ing.name &&
              parseFloat(ing.ingredientInCartQuantity) > 0 &&
              ing.checked ? (
                <div key={ing.id}> == ( إضافة {ing.name} )</div>
              ) : null
            )}
          </td>
          <td className="invoice-table-cell product-quantity-cell">
            {!isNaN(p.productInCartQuantity)
              ? parseFloat(p.productInCartQuantity).toFixed(2)
              : '----'}
            {p.productIngredients.map((ing) =>
              parseFloat(ing.ingredientInCartQuantity) > 0 && ing.checked ? (
                <div key={ing.id}>{ing.ingredientInCartQuantity}</div>
              ) : null
            )}
          </td>
          <td className="invoice-table-cell product-price-cell">
            {getProductSalePrice(p)?.toFixed(2) || '----'}
            {p.productIngredients.map((ing) =>
              String(ing.priceOfUnit) &&
              parseFloat(ing.ingredientInCartQuantity) > 0 &&
              ing.checked ? (
                <div key={ing.id}>{parseFloat(ing.priceOfUnit).toFixed(2)}</div>
              ) : null
            )}
          </td>
          <td className="invoice-table-cell product-price-cell">
            {!isNaN(parseFloat(p.productInCartQuantity))
              ? (
                  getProductSalePrice(p) * parseFloat(p.productInCartQuantity)
                ).toFixed(2)
              : '----'}
            {p.productIngredients.map((ing) =>
              String(ing.priceOfUnit) &&
              parseFloat(ing.ingredientInCartQuantity) > 0 &&
              ing.checked ? (
                <div key={ing.id}>
                  {(
                    parseFloat(ing.priceOfUnit) *
                    parseFloat(ing.ingredientInCartQuantity)
                  ).toFixed(2)}
                </div>
              ) : null
            )}
          </td>
        </tr>
      );
    }
    return (
      <tr key={p.id} className="invoice-product-li">
        <td className="invoice-table-cell product-name-cell">
          {p.name ? p.name : '----'}
        </td>
        <td className="invoice-table-cell product-quantity-cell">
          {!isNaN(p.productInCartQuantity)
            ? parseFloat(p.productInCartQuantity)?.toFixed(2)
            : '----'}
        </td>
        <td className="invoice-table-cell product-price-cell">
          {getProductSalePrice(p)?.toFixed(2) || '----'}
        </td>
        <td className="invoice-table-cell product-price-cell">
          {!isNaN(parseFloat(p.productInCartQuantity))
            ? (
                getProductSalePrice(p) * parseFloat(p.productInCartQuantity)
              ).toFixed(2)
            : '----'}
        </td>
      </tr>
    );
  };

  const renderInvoiceExtraPrice = (extraPriceObj, index) => {
    return (
      <tr key={index} className="invoice-product-li">
        <th
          className="invoice-table-cell col-head"
          style={{ fontWeight: 'normal' }}
        >
          {extraPriceObj.desc ? extraPriceObj.desc : '-------'}
        </th>
        <td className="fake-td invoice-table-cell">======</td>
        <td className="invoice-table-cell product-quantity-cell">
          {extraPriceObj.price ? extraPriceObj.price : '----'}
        </td>
      </tr>
    );
  };

  const renderProducts = () => {
    return (
      <div className="products-filter-wrapper">
        <div className="products-table-total-wrap">
          <table
            className="invoice-products-table"
            style={{ textAlign: `${currentLang == 'ar' ? 'right' : 'left'}` }}
          >
            <thead>
              <tr className="invoice-products-head">
                <th className="invoice-table-cell product-name-cell">
                  {t('Pos.Main.product')}
                </th>
                <th className="invoice-table-cell product-quantity-cell">
                  {t('Pos.Main.quantity')}
                </th>
                <th className="invoice-table-cell product-price-cell">
                  {t('Pos.Main.unitPrice')}
                </th>
                <th className="invoice-table-cell product-price-cell">
                  {t('Pos.Main.price')}
                </th>
              </tr>
            </thead>
            <tbody>
              {selectedProducts.map((p) => renderInvoiceProduct(p))}
            </tbody>
          </table>
          {invoiceExtraPrices?.length > 0 &&
            invoiceExtraPrices[0].desc &&
            parseFloat(invoiceExtraPrices[0].price) >= 0 && (
              <table className="prices-table">
                <tbody>
                  {invoiceExtraPrices.map((extra, index) =>
                    renderInvoiceExtraPrice(extra, index)
                  )}
                </tbody>
              </table>
            )}

          <table
            className="prices-table"
            style={{ textAlign: `${currentLang == 'ar' ? 'right' : 'left'}` }}
          >
            <tbody>
              {invoiceTotalProductsNetPrice >= 0 && (
                <tr className="total-before-discount-wrap">
                  <th className="invoice-table-cell col-head">
                    {t('Pos.Main.totalBeforeDiscount')}
                  </th>
                  <td className="fake-td invoice-table-cell">======</td>
                  <td className="invoice-table-cell discount-value">
                    {invoiceTotalProductsNetPrice}
                  </td>
                </tr>
              )}

              {parseFloat(invoiceTotalProductsPriceAfterDiscount) >= 0 ? (
                <tr className="total-before-discount-wrap">
                  <th className="invoice-table-cell col-head">
                    {t('Pos.Main.totalAfterDiscount')}{' '}
                  </th>
                  <td className="fake-td invoice-table-cell">======</td>
                  <td className="invoice-table-cell discount-value">
                    {invoiceTotalProductsPriceAfterDiscount}
                  </td>
                </tr>
              ) : null}

              <tr className="total-before-discount-wrap">
                <th className="invoice-table-cell col-head">
                  {t('Pos.Main.taxPrice')}
                  {systemSettings?.tax > 0 ? ` ${systemSettings?.tax}%` : ''}
                </th>
                <td className="fake-td invoice-table-cell">======</td>
                <td className="invoice-table-cell discount-value">
                  {/* {(
                    invoiceTotalProductsPriceAfterTax -
                    invoiceTotalProductsNetPrice
                  ).toFixed(2)} */}
                  {totalTax}
                </td>
              </tr>

              {invoiceTotalProductsPriceAfterTax >= 0 && (
                <tr className="total-before-discount-wrap">
                  <th className="invoice-table-cell col-head">
                    {t('Pos.Main.totalAfterTax')}
                  </th>
                  <td className="fake-td invoice-table-cell">======</td>
                  <td className="invoice-table-cell discount-value">
                    {invoiceTotalProductsPriceAfterTax}
                  </td>
                </tr>
              )}

              {/* {parseFloat(invoiceDiscountValue) > 0 &&
                (invoiceDiscountType == '1' || invoiceDiscountType == '2') && (
                  <tr>
                    <th className="invoice-table-cell col-head">
                      إجمالى الخصـــم :{' '}
                    </th>
                    <td className="fake-td invoice-table-cell">======</td>
                    <td className="invoice-table-cell discount-value">
                      {
                        netprice - netpricewithdiscount
                      }
                    </td>
                  </tr>
                )} */}
            </tbody>
          </table>
          {invoiceTotalAfterExtraPrices &&
            parseFloat(invoiceTotalAfterExtraPrices) >= 0 && (
              <div className="grand-total-invoice-price">
                <span> {t('Pos.Main.totalPrice')}</span>
                <span className="bold-font">
                  {invoiceTotalAfterExtraPrices}
                </span>
              </div>
            )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (successOrder) {
      resetPos();
      setStep(0);
      setSucessOrder(null);
      history.push(
        routerLinks.printInvoicePage(successOrder?.id ? successOrder.id : '')
      );
    }
  }, [successOrder]);

  return (
    <>
      <div>
        <div
          className="pos-header-links-wrapper"
          style={{ marginBottom: '15px' }}
        >
          <SharedLayoutBtns />
        </div>

        <div ref={ref} className="pos-whole-receipt-wrap">
          <div className="pos-receipt-wrap">
            <div className="store-logo-name-wrap">
              <img
                src={
                  posSettings?.image
                    ? posSettings.image
                    : '/assets/imgs/logo/logo-dark.svg'
                }
                alt="Logo"
              />
              <div className="store-name">
                {posSettings?.name ? posSettings.name : ''}
              </div>
            </div>

            {(!selectedProducts || selectedProducts?.length === 0) && (
              <div className="empty-cart-wrap">
                {/* <img src={emptyCartImg} alt="empty cart" /> */}
                <AddToCartIcon />

                <p> {t('Pos.Main.noRequestsRegistered')}</p>
              </div>
            )}
            {console.log("selectedInvoice",selectedProducts)}
            {selectedProducts?.length > 0 && (
              <div className="invoice-content">
                <div className="invoice-number">
                  {/* <span>رقم الفاتورة</span> */}
                  <span className="number-span">
                    {selectedInvoice ? selectedInvoice.id : '###########'}
                  </span>
                </div>
                <div className="system-info" style={{display:'flex', flexDirection: 'column'}}>
                  <div className="cell-wrap address-wrap">
                    {/* <span className="info-cell-title">عنوان المتجر : </span> */}
                    <span className="info-cell-value">
                      {posSettings?.address && posSettings.address}
                    </span>
                  </div>
                  <div style={{display:'flex',alignItems:'center', justifyContent:'space-between'}}>
                    <div className="cell-wrap vat-wrap">
                      <span className="info-cell-title">
                        {t('Pos.Main.taxNumber')}
                      </span>
                      <span className="info-cell-value">
                        {posSettings?.tax_number ? posSettings.tax_number : ''}
                      </span>
                    </div>
                    <div className="cell-wrap date-wrap">
                      <span className="info-cell-title">
                        {t('Pos.Main.invoiceDate')}
                      </span>
                      <span className="info-cell-value">{`${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`}</span> 
                    </div>
                  </div>
                  {/* <div className="cell-wrap time-wrap">
                    <span className="info-cell-title">
                      {t('Pos.Main.taxNumber')}
                    </span>
                      // <span className="info-cell-value">10:12</span> 
                  </div> */}
                </div>

                {renderProducts()}
              </div>
            )}
          </div>
          {selectedProducts?.length > 0 && (
            <div className="action-btns-wrap">
              <Button onClick={() => setLaterInvoiceModalOpened(true)}>
                {t('Pos.Main.bookLater')}
              </Button>
              <Button
                onClick={() => {
                  resetPos();
                  setStep(0);
                }}
              >
                {t('Pos.Main.cancelOperation')}
              </Button>

              <div className={`step-btns ${i18n.dir()}`}>
                {step > 0 && !selectedInvoice && (
                  <Button
                    type="button"
                    onClick={() => setStep((currState) => currState - 1)}
                  >
                    <span>{t('Pos.Main.previousStep')}</span>
                  </Button>
                )}
                {step < childrenArray.length && (
                  <Button
                    onClick={() => {
                      if (isLastStep) {
                        handleInvoiceSubmit();
                      } else {
                        setStep((currState) => currState + 1);
                      }
                    }}
                    style={{
                      gridColumn: `${
                        (step < childrenArray.length && step === 0) ||
                        selectedInvoice
                          ? '1 / -1'
                          : '2 / 3'
                      }`
                    }}
                  >
                    <span>{`${
                      isLastStep
                        ? t('Pos.Main.completeProcess')
                        : t('Pos.Main.follow')
                    }`}</span>
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {isSubmittingOrder && <LoadingModal />}

      {/* sucess modal */}
      {/* {successOrder && (
        <LoadingModal clsName="success-order-modal">
          <div style={{ display: 'grid', placeItems: 'center' }}>
            <img src="/assets/imgs/logo/Fatoorah-mark.png" alt="Fatoorah" />
          </div>
          <p>العملية تمت بنجاح</p>

          <div className="sucess-btns-wrap">
            <Button
              className="print-link"
              onClick={() => {
                resetPos();
                setStep(0);
                setSucessOrder(null);
                history.push(
                  routerLinks.printInvoicePage(
                    successOrder?.id ? successOrder.id : ''
                  )
                );
              }}
            >
              طباعة الفاتورة
            </Button>
            <Button
              className="print-link"
              onClick={() => {
                resetPos();
                setStep(0);
                setSucessOrder(null);
                history.push(routerLinks.posPage);
              }}
            >
              عملية جديدة
            </Button>
          </div>
        </LoadingModal>
      )} */}
    </>
  );
});

export default PosInvoice;
