import React, { useContext } from 'react';
import PageTable from './PageTable';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import TableFilter from './TableFilter';
import './SaleInvoicesPage.scss';
import SelectedSaleInvoiceModal from './SelectedSaleInvoiceModal';
import { useHistory } from 'react-router-dom';
import ReactHookFormContext from '../../contexts/react-hook-form-context/ReactHookFormContext';
import LaterInvoiceModal from '../../components/pos-stepper-from/LaterInvoiceModal';
import Links from '../../components/app/Links';
import SharedLayoutBtns from '../../components/app/pos-section/SharedLayoutBtns';
import NotificationsProfile from '../../components/app/pos-section/notifications-profile/NotificationsProfile';
import ReturnModal from '../../components/return-modal/ReturnModal';
import ReturnModalContext from '../../contexts/return-modal-context/ReturnModalContext';
import { useTranslation } from 'react-i18next';

const SaleInvoicesPage = () => {
  const routerLinks = Links();
  const history = useHistory();
  const {
    setSelectedInvoice,
    selectedInvoice,
    resetPos,
    laterInvoiceModalOpened,
    selectedSaleInvoiceModalOpned
  } = useContext(PosInvoiceContext);

  const { setStep } = useContext(ReactHookFormContext);
  const { returnModalOpened } = useContext(ReturnModalContext);
  const { t } = useTranslation();

  return (
    <div className="invoices-page">
      <div className="pos-navbar">
        <SharedLayoutBtns />
        <NotificationsProfile />
      </div>
      <div className="page-head-wrap">
        <div className="page-title">
          <h2 className="bold-font" style={{fontSize:"35px",marginTop:"34px"}}>{t('Invoices.SalesInvoices')}</h2>
         {/*  <p>{t('Invoices.ShowAndDisplayAllSalesInvoices')}</p> */}
        </div>
      </div>

      <div className="custom-filter-add-section1"  style={{marginTop:"31px"}}>
        <div className="add-section">
          <div
            className="add-btn"
            onClick={() => {
              setSelectedInvoice(null);
              setStep(0);
              resetPos();
              history.push(routerLinks.posPage);
            }}
          >
            <span className="text-span">
              {t('SalesInvoices.AddaNewInvoice')}
            </span>
          </div>
        </div>
        <TableFilter />
      </div>

      <PageTable />
      {returnModalOpened && selectedInvoice && <ReturnModal />}

      {selectedSaleInvoiceModalOpned && <SelectedSaleInvoiceModal />}
      {laterInvoiceModalOpened && <LaterInvoiceModal />}
    </div>
  );
};

export default SaleInvoicesPage;
