import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Checkbox, Divider, Form, Spin } from 'antd';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import AntdCheckbox from '../../../common/antd-form-components/AntdCheckbox';
import AntdRadioGroup from '../../../common/antd-form-components/AntdRadioGroup';
import { store } from 'react-notifications-component';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import { useContext } from 'react';
import UserContext from '../../../contexts/user-context/UserProvider';
import addInvoicesSettingsApi from '../../../apis/settings-apis/system-settings-api/addInvoicesSettingsApi';
import AntdTextarea from '../../../common/antd-form-components/AntdTextarea';
import { useHistory } from 'react-router-dom';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import getSystemSettingsApi from '../../../apis/settings-apis/system-settings-api/getSystemSettingsApi';
import './SystemSettingsPage.scss';
import { useTranslation } from 'react-i18next';

const InvoiceSettingsPage = () => {
  const customApiRequest = useCustomApiRequest();
  const { user } = useContext(UserContext);
  const SystemSettings = useSystemSettings();
  const [render, setRender] = useState(0);
  const { t } = useTranslation();

  const schema = Yup.object().shape({
    lang: Yup.string().optional(t('SystemSettings.chooseLangValidation')),
    template: Yup.string().required(t('SystemSettings.chooseTemplate')),
    change_invoice_num: Yup.string().optional(''),
    qrcode: Yup.string().optional(''),
    barcode: Yup.string().optional(''),
    logo: Yup.string().optional(''),
    terms: Yup.string().optional(''),
    terms_text: Yup.string().optional('')
    // .min(10, 'يجب عليك إضافة 10 حروف على الأقل')
    // .max(2000, 'لا يسمح أن تتجاوز 2000 حرف')
  });
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      lang: 'ar',
      change_invoice_num:
        SystemSettings?.invoiceSetting?.change_invoice_num &&
        SystemSettings?.invoiceSetting?.change_invoice_num,
      qrcode:
        SystemSettings?.invoiceSetting?.qrcode &&
        SystemSettings?.invoiceSetting?.qrcode,
      barcode:
        SystemSettings?.invoiceSetting?.barcode &&
        SystemSettings?.invoiceSetting?.barcode,
      logo:
        SystemSettings?.invoiceSetting?.logo &&
        SystemSettings?.invoiceSetting?.logo,
      template: 'A4',
      terms:
        SystemSettings?.invoiceSetting?.terms &&
        SystemSettings?.invoiceSetting?.terms,
      terms_text: ''
    }
  });
  useEffect(() => {
    if (SystemSettings) {
      setValue('terms', SystemSettings?.invoiceSetting?.terms ? 1 : 0);
      setValue('logo', SystemSettings?.invoiceSetting?.logo ? 1 : 0);
      setValue('barcode', SystemSettings?.invoiceSetting?.barcode ? 1 : 0);
      setValue('qrcode', SystemSettings?.invoiceSetting?.qrcode ? 1 : 0);
      setValue(
        'change_invoice_num',
        SystemSettings?.invoiceSetting?.change_invoice_num ? 1 : 0
      );
      setValue(
        'terms_text',
        SystemSettings?.invoiceSetting?.terms_text
          ? SystemSettings?.invoiceSetting?.terms_text
          : ''
      );
    }
  }, [SystemSettings]);

  useEffect(() => {
    if (!watch('terms')) {
      control.fieldsRef.current.terms_text._f.ref.value = '';
      control.fieldsRef.current.terms_text._f.value = '';
    }
    setRender(render + 1);
  }, [SystemSettings, watch('terms'), watch('qrcode')]);
  const onSubmit = async (data) => {
    const settingsData = {
      ...data,
      qrcode:
        data.qrcode === 'true' || data.qrcode === '1'
          ? 1
          : data.qrcode === 'false' || data.qrcode === '0'
          ? 0
          : 0,
      barcode:
        data.barcode === 'true' || data.barcode === '1'
          ? 1
          : data.barcode === 'false' || data.barcode === '0'
          ? 0
          : 0,
      logo:
        data.logo === 'true' || data.logo === '1'
          ? 1
          : data.logo === 'false' || data.logo === '0'
          ? 0
          : 0,
      change_invoice_num:
        data.change_invoice_num === 'true' || data.change_invoice_num === '1'
          ? 1
          : data.change_invoice_num === 'false' ||
            data.change_invoice_num === '0'
          ? 0
          : 0,
      terms:
        data.terms === 'true' || data.terms === '1'
          ? 1
          : data.terms === 'false' || data.terms === '0'
          ? 0
          : 0
    };

    customApiRequest(
      addInvoicesSettingsApi(user?.token, settingsData),
      (res) => {
        if (res?.data) {
          store.addNotification({
            title: res?.data?.message,
            message: res?.data?.message,
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              showIcon: true,
              onScreen: true
            }
          });
        }
      },
      (error) => {}
    );
  };

  const [form] = Form.useForm();
  return (
    <div className="invoice-settings">
      {/* <h2>{t('SystemSettings.cashierInvoicesSettings')}</h2> */}
     {/*  {SystemSettings?.subscriptionType == 2 ? (
        <h2>{t('SystemSettings.invoicesSettings')}</h2>
      ) : (
        <h2>{t('SystemSettings.cashierInvoicesSettings')}</h2>
      )} */}

           <h2>{t('SystemSettings.invoicesSettings')}</h2>
      <Form
        className="custom-shared-form"
        form={form}
        layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        {SystemSettings ? (
          <>
            {/* <div className="radios-field-lang no-border">
              <AntdRadioGroup
                name="lang"
                className="form-radio-group"
                control={control}
                label={t('SystemSettings.languageValidation')}
                validateStatus={errors?.lang ? 'error' : ''}
                errorMsg={errors?.lang?.message}
                defaultValue={'ar'}
                radios={[
                  {
                    title: 'عربى',
                    value: 'ar'
                  }
                  // {
                  //   title: 'English',
                  //   value: 'en'
                  // }
                ]}
              />
            </div> */}
            <AntdCheckbox
              name="change_invoice_num"
              label={t('SystemSettings.ChangeTheInvoiceNumberByDefault')}
              control={control}
              checked={
                render > 2
                  ? watch('change_invoice_num')
                  : SystemSettings?.invoiceSetting?.change_invoice_num
              }
            />
            {SystemSettings?.subscriptionType !== 2 && (
              <>
                <AntdCheckbox
                  name="qrcode"
                  label={`${t('SystemSettings.showQRCode')} (${t(
                    'SystemSettings.Specialforcashierbills'
                  )})`}
                  control={control}
                  checked={
                    render > 2
                      ? watch('qrcode')
                      : SystemSettings?.invoiceSetting?.qrcode
                  }
                />
                <AntdCheckbox
                  name="barcode"
                  // label={t('SystemSettings.showBarCode')}
                  label={`${t('SystemSettings.showBarCode')} (${t(
                    'SystemSettings.Specialforcashierbills'
                  )})`}
                  control={control}
                  checked={
                    render > 2
                      ? watch('barcode')
                      : SystemSettings?.invoiceSetting?.barcode
                  }
                />
                <AntdCheckbox
                  name="logo"
                  // label={t('SystemSettings.showCompanyLogo')}
                  label={`${t('SystemSettings.showCompanyLogo')} (${t(
                    'SystemSettings.Specialforcashierbills'
                  )})`}
                  control={control}
                  checked={
                    render > 2
                      ? watch('logo')
                      : SystemSettings?.invoiceSetting?.logo
                  }
                />
              </>
            )}

            <AntdCheckbox
              name="terms"
              label={t('SystemSettings.termsAndConditions')}
              control={control}
              checked={
                render > 2
                  ? watch('terms')
                  : SystemSettings?.invoiceSetting?.terms
              }
            />
            {(
              render > 2
                ? watch('terms')
                : SystemSettings?.invoiceSetting?.terms
            ) ? (
              <>
                <div className="text-area-label-wrap">
                  <div className="text-field-wrap">
                    <AntdTextarea
                      className="form-text-area white-bg"
                      name="terms_text"
                      rows={3}
                      placeholder={t(
                        'SystemSettings.termsAndConditionsPlaceholder'
                      )}
                      reset={!watch('terms')}
                      validateStatus={
                        errors?.termsAndConditions
                          ? t('SystemSettings.addConditions')
                          : ''
                      }
                      errorMsg={errors?.termsAndConditions?.message}
                      control={control}
                      value={
                        render > 2
                          ? SystemSettings?.invoiceSetting?.terms_text
                          : watch('terms_text')
                      }
                    />
                  </div>
                </div>
              </>
            ) : null}
            {SystemSettings?.subscriptionType !== 2 && (
              <div className="radios-field-lang no-border">
                <AntdRadioGroup
                  name="template"
                  className="form-radio-group"
                  control={control}
                  label={t('SystemSettings.chooseInvoiceTemplate')}
                  validateStatus={errors?.template ? 'error' : ''}
                  errorMsg={errors?.template?.message}
                  defaultValue={'A4'}
                  radios={[
                    {
                      title: t('SystemSettings.thermal'),
                      value: 'thermal'
                    },
                    {
                      title: 'A4',
                      value: 'A4'
                    }
                  ]}
                />
              </div>
            )}
          </>
        ) : (
          <div
            style={{
              height: '250px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Spin />
          </div>
        )}
        <div className="btn">
          <Button
            className="submit-btn"
            htmlType="submit"
            type="primary"
            // icon={<LoginOutlined />}
            // loading={isSubmittingForm}
          >
            {t('SystemSettings.save')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default InvoiceSettingsPage;
