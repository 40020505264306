/* eslint-disable eqeqeq */
import { Descriptions, Empty, Table } from 'antd';
import QRCode from 'qrcode.react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import WaterMark from '../../../common/water-mark/WaterMark';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';

const ReceiptDetailsComponentProducts = React.forwardRef((props, ref) => {
  // const systemSettings = useSystemSettings();
  const { selectedReceipt } = props;
  const systemSettings = useSystemSettings();
  const { t } = useTranslation();

  const renderDiscountValue = (record) => {
    if (record.discountType == '1') {
      // fixed discount price
      return record.discountPrice;
    } else if (record.discountType == '2') {
      // percentage discount
      return `${record.discountPrice}`;
    } else {
      // no discount
      return 'بدون خصم';
    }
  };

  const renderTitle = (invoice_type) => {
    if (invoice_type === 1) {
      return `${t('Quotations.Seller')} : `;
    } else if (invoice_type === 2) {
      return `${t('Quotations.buyer')}  : `;
    }
    return '';
  };

  const renderSupplier = (obj, type) => {
    const {
      name,
      phone,
      address,
      addressCode,
      VATRegistrationNumber,
      tax_number,
      taxNumber,
      commercialRecord
    } = obj;
    return (
      <div className="supplier-details-wrap">
        <Descriptions title={renderTitle(type)} bordered column={1}>
          {name && (
            <Descriptions.Item label={t('Quotations.Name')}>
              {name}
            </Descriptions.Item>
          )}
          {phone && (
            <Descriptions.Item label={t('Quotations.PhoneNumber')}>
              {phone}
            </Descriptions.Item>
          )}
          {address && (
            <Descriptions.Item label={t('Quotations.Address')}>
              {address}
            </Descriptions.Item>
          )}
          {!address && addressCode && (
            <Descriptions.Item label={t('Quotations.Address')}>
              {addressCode}
            </Descriptions.Item>
          )}
          {VATRegistrationNumber && (
            <Descriptions.Item label={t('Quotations.VATNumber')}>
              {VATRegistrationNumber}
            </Descriptions.Item>
          )}

          {tax_number && (
            <Descriptions.Item label={t('Quotations.VATNumber')}>
              {tax_number}
            </Descriptions.Item>
          )}
          {taxNumber && (
            <Descriptions.Item label={t('Quotations.VATNumber')}>
              {taxNumber}
            </Descriptions.Item>
          )}
          {commercialRecord && (
            <Descriptions.Item
              label={t('Quotations.CommercialRegistrationNumber')}
            >
              {commercialRecord}
            </Descriptions.Item>
          )}
          {/* {buildingNum && (
            <Descriptions.Item label="رقـــم المبنى">
              {buildingNum}
            </Descriptions.Item>
          )}
          {streetName && (
            <Descriptions.Item label="اســم الشارع">
              {streetName}
            </Descriptions.Item>
          )}
          {district && (
            <Descriptions.Item label="الحــى">{district}</Descriptions.Item>
          )}
          {city && (
            <Descriptions.Item label="المدينة">{city}</Descriptions.Item>
          )}
          {country && (
            <Descriptions.Item label="البـلد">{country}</Descriptions.Item>
          )}
          {postalCode && (
            <Descriptions.Item label="الرمز البريدى">
              {postalCode}
            </Descriptions.Item>
          )} */}
          {/* {addressCode && (
            <Descriptions.Item label="الرقم الاضافى للعنوان">
              {addressCode}
            </Descriptions.Item>
          )} */}
          {/* {VATRegistrationNumber && (
            <Descriptions.Item label="رقم ضريبة القيمة المضافة">
              {VATRegistrationNumber}
            </Descriptions.Item>
          )}
          {tax_number && (
            <Descriptions.Item label="رقم ضريبة القيمة المضافة">
              {tax_number}
            </Descriptions.Item>
          )}
          {anotherId && (
            <Descriptions.Item label="معرف أخر">{anotherId}</Descriptions.Item>
          )} */}
        </Descriptions>
      </div>
    );
  };

  const renderModels = () => {
    const details = selectedReceipt.details;
    if (details?.length > 0) {
      return (
        <div className="models-wrap pagebreak">
          <div className="models-title bold-font">
            {t('Quotations.DescriptionofTheGoodorService')}:
          </div>
          <Table
            bordered
            locale={{
              emptyText: (
                <Empty description={false}>
                  {t('Quotations.NoDataAvailable')}
                </Empty>
              )
            }}
            // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
            pagination={false}
            // className="antd-custom-table"
            dataSource={details.map((item, index) => ({
              ...item,
              key: item.id,
              index
            }))}
            columns={[
              {
                title: t('Quotations.M'),
                dataIndex: 'id',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-index bold-font">
                      <span>{record.index + 1}</span>
                      {/* <span># {record.id}</span> */}
                    </div>
                  );
                }
              },
              {
                title: t('Quotations.Name'),
                dataIndex: 'name',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text desc-wrap">
                      <span>{record.name || record?.product?.name}</span>
                    </div>
                  );
                }
              },
              {
                title: t('Quotations.Description'),
                dataIndex: 'desc',
                render: (_, record) => {
                  const recordText = String(record.desc);
                  if (record?.desc) {
                    return (
                      <div className="row-cell row-text desc-wrap">
                        {recordText}
                      </div>
                    );
                  } else {
                    return '----';
                  }
                }
              },
              {
                title: t('Quotations.quantity'),

                dataIndex: 'quantity',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.quantity}</span>
                    </div>
                  );
                }
              },

              // {
              //   title: 'المستودع',
              //   dataIndex: 'warehouse',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-text">
              //         <span>{record?.stock?.name}</span>
              //       </div>
              //     );
              //   }
              // },
              {
                title: t('Quotations.Price'),
                dataIndex: 'price',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.price}</span>
                    </div>
                  );
                }
              },
              {
                title: t('Quotations.rebate'),

                dataIndex: 'discount',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{renderDiscountValue(record)}</span>
                    </div>
                  );
                }
              },
              {
                title: t('Quotations.Tax'),

                dataIndex: 'tax',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.tax} %</span>
                    </div>
                  );
                }
              },
              {
                title: t('Quotations.TaxRate'),

                dataIndex: 'totalTax',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.totalTax}</span>
                    </div>
                  );
                }
              },
              // {
              //   title: 'قبل الخصم',
              //   dataIndex: 'priceBeforeDiscount',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-text">
              //         <span>{record.priceBeforeDiscount}</span>
              //       </div>
              //     );
              //   }
              // },
              // {
              //   title: 'الخصــم',
              //   dataIndex: 'discount',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-text">
              //         <span>{renderDiscountValue(record)}</span>
              //       </div>
              //     );
              //   }
              // },
              // {
              //   title: 'سعر الخصم',
              //   dataIndex: 'discount',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-text">
              //         <span>{record.discountPrice}</span>
              //       </div>
              //     );
              //   }
              // },
              {
                title: t('Quotations.Total'),

                dataIndex: 'discount',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.totalPrice}</span>
                    </div>
                  );
                }
              }
            ]}
            // loading={isLoading}
            // scroll={{ x: 400 }}
            footer={false}
          />
        </div>
      );
    }
    return null;
  };

  const renderExtraPrices = () => {
    const addPrices = selectedReceipt.addPrices;
    if (addPrices?.length > 0) {
      return (
        <div className="models-wrap">
          <div className="models-title bold-font">
            {t('Quotations.AdditionalPrices')} :
          </div>
          <Table
            className="invoice-extra-prices-table"
            bordered
            showHeader={false}
            locale={{
              emptyText: (
                <Empty description={false}>
                  {t('Quotations.AdditionalPrices')}
                </Empty>
              )
            }}
            pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
            // className="antd-custom-table"
            dataSource={addPrices.map((item) => ({
              ...item,
              key: item.id
            }))}
            columns={[
              // {
              //   title: 'الكود',
              //   dataIndex: 'id',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-index bold-font">
              //         <span># {record.id}</span>
              //       </div>
              //     );
              //   }
              // },
              {
                title: t('Quotations.description'),

                dataIndex: 'desc',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text extra-prices-cell">
                      <span>{record.desc}</span>
                    </div>
                  );
                }
              },
              {
                title: t('Quotations.Price'),

                dataIndex: 'price',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text extra-prices-cell">
                      <span>{record.price}</span>
                    </div>
                  );
                }
              }
            ]}
            // loading={isLoading}
            // scroll={{ x: 400 }}
            footer={false}
          />
        </div>
      );
    }
    return null;
  };

  const renderInovicePrices = () => {
    const {
      productPrice,
      discountType,
      discountPrice,
      discountValue,
      additionPrice,
      totalBeforeTax,
      taxPrice,
      totalPrice
    } = selectedReceipt;
    return (
      <div className="supplier-details-wrap">
        <Descriptions
          title={`${t('Quotations.TotalAmounts')} :`}
          bordered
          column={1}
        >
          {productPrice && (
            <Descriptions.Item
              label={t('Quotations.TotalUnitPriceAfterDiscount')}
            >
              {productPrice}
            </Descriptions.Item>
          )}
          {discountPrice && (
            <Descriptions.Item label={t('Quotations.rebate')}>
              {/* {renderDiscountValue({
                discountType,
                discountValue
              })} */}
              {discountPrice}
            </Descriptions.Item>
          )}
          {/* {discountPrice && (
            <Descriptions.Item label="سعر الخصم">
              {discountPrice}
            </Descriptions.Item>
          )} */}
          {additionPrice && (
            <Descriptions.Item label={t('Quotations.AdditionalPrices')}>
              {additionPrice}
            </Descriptions.Item>
          )}
          {totalBeforeTax && (
            <Descriptions.Item label={t('Quotations.TotalPriceBeforeVATAdded')}>
              {totalBeforeTax}
            </Descriptions.Item>
          )}
          {taxPrice && (
            <Descriptions.Item label={t('Quotations.taxPrice')}>
              {taxPrice}
            </Descriptions.Item>
          )}
          {totalPrice && (
            <Descriptions.Item
              style={{
                fontSize: '18px',
                color: '#000',
                fontWeight: 'bold'
              }}
              label={t('purchaseInvoices.priceAfterTax')}
            >
              {totalPrice}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    );
  };
  return (
    <div
      className="invoice-details-wrap"
      ref={ref}
      style={{
        padding: '32px 32px',
        /* direction: 'rtl', */
        position: 'relative'
        // display: 'grid',
        // gap: '16px'
      }}
    >
      {systemSettings?.package_id == 1 && (
        <WaterMark text={t('freeTrial.priceAfterTax')} />
      )}

      {/* <p
        style={{
          fontSize: 16,
          color: '#000',
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: 6
        }}
      >
        {selectedReceipt?.setting?.ref_id}
      </p> */}

      {/* {selectedReceipt?.invoice_type === 2 ? (
        <div
          className="img-wrap"
          style={{
            display: 'grid',
            placeItems: 'center',
            minWidth: '92px',
            minHeight: '92px'
          }}
        >
          {selectedReceipt?.setting?.image ? (
            <img
              className="system-logo"
              src={selectedReceipt?.setting.image}
              alt="logo"
              style={{
                // width: '92px',
                height: '92px'
              }}
            />
          ) : null}
        </div>
      ) : null} */}
      <div
        className="inovice-nums-qrcode"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          gap: '22px',
          alignItems: 'center'
          /*  direction: 'rtl' */
        }}
      >
        <div
          className="qr-code-logo-wrap"
          style={{
            display: 'flex',
            gap: '22px',
            alignItems: 'center',
            direction: 'rtl',
            justifyContent: 'space-between'
          }}
        >
          <div
            className="img-wrap"
            style={{
              display: 'grid',
              placeItems: 'center',
              // minWidth: '92px',
              minHeight: '92px'
            }}
          >
            {selectedReceipt?.setting?.image ? (
              <img
                className="system-logo"
                src={selectedReceipt?.setting.image}
                alt="logo"
                style={{
                  // width: '92px',
                  height: '60px'
                }}
              />
            ) : null}
          </div>
          <p
            style={{
              fontSize: 20,
              color: '#000',
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: 12
            }}
          >
            <span>{t('Quotations.ShowPrice')}</span>
          </p>
          <div
            style={{
              visibility: 'hidden'
            }}
          >
            -
          </div>
        </div>
      </div>

      <div
        className="inovice-nums-qrcode"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          gap: '22px',
          alignItems: 'center'
          /* direction: 'rtl' */
        }}
      >
        <Descriptions title="" bordered column={1}>
          {selectedReceipt?.invoiceNumber && (
            <Descriptions.Item label={t('Quotations.OfferNo')}>
              {selectedReceipt.invoiceNumber}
            </Descriptions.Item>
          )}
          {selectedReceipt?.dueDate && (
            <Descriptions.Item label={t('Quotations.IssueDate')}>
              {selectedReceipt.dueDate}
            </Descriptions.Item>
          )}
          {selectedReceipt?.expirationDate && (
            <Descriptions.Item label={t('Quotations.OfferExpiryDate')}>
              {selectedReceipt.expirationDate}
            </Descriptions.Item>
          )}
          <Descriptions.Item label={t('Quotations.OfferReleaseTime')}>
            {selectedReceipt?.time ? selectedReceipt.time : '---'}
          </Descriptions.Item>
        </Descriptions>

        <div className="qr-code-wrap">
          {/* <img className="qr-code-img" src={qrcodeImg} alt="qrcode" /> */}
          <QRCode
            value={selectedReceipt?.qrString ? selectedReceipt.qrString : ''}
            width={92}
            height={92}
            renderAs="svg"
          />
          {/* <QRCode
            value={
              selectedReceipt?.encryptId
                ? `${
                    process.env.REACT_APP_FATOORAH_URL
                  }/invoice-details/${String(selectedReceipt.encryptId)}`
                : ''
            }
          /> */}
        </div>
      </div>
      <div
        className="supp-buyer-wrap"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '12px'
        }}
      >
        {selectedReceipt?.setting &&
          renderSupplier(
            selectedReceipt?.setting,
            selectedReceipt?.invoice_type === 1 ? 2 : 1
          )}
        {selectedReceipt?.user &&
          selectedReceipt?.user?.name &&
          renderSupplier(selectedReceipt?.user, selectedReceipt?.invoice_type)}
      </div>

      {renderModels()}
      {renderExtraPrices()}
      {renderInovicePrices()}
      {selectedReceipt?.note && (
        <>
          <h3 className="note-modal-box">{t('Quotations.Notes')}</h3>
          <div className="note">
            <p>{selectedReceipt?.note}</p>
          </div>
        </>
      )}

      <div>
        <Descriptions
          title=""
          bordered
          column={1}
          labelStyle={{ fontWeight: 'bold', width: '150px' }}
        >
          {selectedReceipt?.setting?.invoiceSetting?.terms && (
            <Descriptions.Item label={t('SystemSettings.termsAndConditions')}>
              {selectedReceipt?.setting?.invoiceSetting?.terms_text
                ?.toString()
                .split('\r\n')
                .map((item) => (
                  <p>{item}</p>
                ))}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>

      <div
        style={{
          textAlign: 'center',
          padding: '10px'
        }}
      >
        {selectedReceipt?.setting?.slogan ? selectedReceipt.setting.slogan : ''}
      </div>
    </div>
  );
});

export default ReceiptDetailsComponentProducts;
