/* eslint-disable eqeqeq */
import { Empty, Table, Descriptions } from 'antd';
import QRCode from 'qrcode.react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import WaterMark from '../../../common/water-mark/WaterMark';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import './receiptDetailsComponentV2.scss';
const ReceiptDetailsComponentV2 = React.forwardRef((props, ref) => {
  const { selectedReceipt } = props;
  const systemSettings = useSystemSettings();
  const { t } = useTranslation();
  const renderDiscountValue = (record) => {
    if (record.discountType == '1') {
      // fixed discount price
      return record.discountPrice;
    } else if (record.discountType == '2') {
      // percentage discount
      return `${record.discountPrice}`;
    } else {
      // no discount
      return t('purchaseInvoices.withoutDiscount');
    }
  };

  const rowWrapStyles = {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gap: '8px'
  };

  const renderUser = (obj, type) => {
    const {
      name,
      phone,
      address,
      addressCode,
      VATRegistrationNumber,
      tax_number,
      taxNumber,
      commercialRecord
    } = obj;
    return (
      <div
        className="supplier-details-wrap"
        style={{
          display: 'grid'
          /* width: 'fit-content' */
        }}
      >
        <div
          style={{
            marginBottom: 2,
            fontWeight: 'bold'
          }}
        ></div>
        <div
          style={{
            display: 'grid'
          }}
        >
          {name && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">{t('purchaseInvoices.name')} :</div>
              <div className="row-value">{name}</div>
            </div>
          )}
          {phone && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">{t('purchaseInvoices.phone')} :</div>
              <div className="row-value">{phone}</div>
            </div>
          )}
          {address && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">{t('purchaseInvoices.address')} :</div>
              <div className="row-value">{address}</div>
            </div>
          )}
          {!address && addressCode && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">{t('purchaseInvoices.address')} :</div>
              <div className="row-value">{addressCode}</div>
            </div>
          )}
          {VATRegistrationNumber && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">
                {t('purchaseInvoices.taxNumber')} :
              </div>
              <div className="row-value">{VATRegistrationNumber}</div>
            </div>
          )}
          {tax_number && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">
                {t('purchaseInvoices.taxNumber')} :
              </div>
              <div className="row-value">{tax_number}</div>
            </div>
          )}
          {taxNumber && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">
                {t('purchaseInvoices.taxNumber')} :
              </div>
              <div className="row-value">{taxNumber}</div>
            </div>
          )}
          {commercialRecord && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">
                {t('purchaseInvoices.CommercialRegistryNumber')} :
              </div>
              <div className="row-value">{commercialRecord}</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderModels = () => {
    const products = selectedReceipt.products;
    if (products?.length > 0) {
      return (
        <div className="models-wrap pagebreak">
          <div
            className="models-title bold-font"
            style={{
              marginBottom: 2,
              fontWeight: 'bold'
            }}
          >
            {t('purchaseInvoices.serviceDescription')} :
          </div>

          <Table
            className="v2-invoice-table"
            bordered
            locale={{
              emptyText: (
                <Empty description={false}>
                  {t('purchaseInvoices.noData')}
                </Empty>
              )
            }}
            pagination={false}
            // pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
            // className="antd-custom-table"
            dataSource={products.map((item, index) => ({
              ...item,
              key: item.id,
              index
            }))}
            columns={[
              {
                title: 'م',
                dataIndex: 'id',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-index bold-font">
                      <span>{record.index + 1}</span>
                      {/* <span># {record.id}</span> */}
                    </div>
                  );
                }
              },
              {
                title: t('purchaseInvoices.name'),
                dataIndex: 'name',
                width: 150,
                render: (_, record) => {
                  const recordText = String(
                    record?.name ||
                      record?.product?.name ||
                      record[record.index]?.product?.name
                  );
                  // const wordsCount = countWords(String(record?.name || record?.product?.name || record[record.index]?.product?.name));
                  // if ((record?.name || record?.product?.name || record[record.index]?.product?.name) && wordsCount > 0) {
                  //   if (wordsCount > 3) {
                  //     return (
                  //       <Popover title="اقرأ المزيد" content={record?.name || record?.product?.name || record[record.index]?.product?.name}>
                  //         <Button size="small" type="text">
                  //           <p className="has-read-more">
                  //             <span>{trimWords(recordText, 4)}</span>
                  //             <ReadOutlined
                  //               className="read-more-book"
                  //               style={{ margin: '0 4px' }}
                  //             />
                  //           </p>
                  //         </Button>
                  //       </Popover>
                  //     );
                  //   } else
                  //     return (
                  //       <div className="row-cell row-text">{recordText}</div>
                  //     );
                  // }
                  return (
                    <div className="row-cell row-text desc-wrap">
                      <span>
                        {record.name ||
                          record?.product?.name ||
                          record[record.index]?.product?.name}
                      </span>
                    </div>
                  );
                }
              },
              {
                title: t('purchaseInvoices.description'),
                dataIndex: 'desc',
                render: (_, record) => {
                  const recordText = String(record?.desc);
                  // const wordsCount = countWords(String(record.desc));
                  // if (record.desc && wordsCount > 0) {
                  //   if (wordsCount > 10) {
                  //     return (
                  //       <Popover title="اقرأ المزيد" content={record.desc}>
                  //         <Button size="large" type="text">
                  //           <p className="has-read-more">
                  //             <span>{trimWords(recordText, 4)}</span>
                  //             <ReadOutlined
                  //               className="read-more-book"
                  //               style={{ margin: '0 4px' }}
                  //             />
                  //           </p>
                  //         </Button>
                  //       </Popover>
                  //     );
                  //   } else
                  //     return (
                  //       <div className="row-cell row-text">{recordText}</div>
                  //     );
                  // }

                  if (record?.desc) {
                    return (
                      <div className="row-cell row-text desc-wrap">
                        {recordText}
                      </div>
                    );
                  } else {
                    return '----';
                  }
                }
              },
              {
                title: t('purchaseInvoices.quantity'),
                dataIndex: 'quantity',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>
                        {selectedReceipt?.type == 2 &&
                          record?.quantity > 0 &&
                          ' - '}
                        {record.quantity}
                      </span>
                    </div>
                  );
                }
              },
              // {
              //   title: 'المستودع',
              //   dataIndex: 'warehouse',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-text">
              //         <span>{record?.stock?.name}</span>
              //       </div>
              //     );
              //   }
              // },
              {
                title: t('purchaseInvoices.unitPrice'),
                dataIndex: 'price',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>
                        {/* {selectedReceipt?.type == 2 && record?.price > 0 && " - "} */}
                        {record.price}
                      </span>
                    </div>
                  );
                }
              },
              // {
              //   title: 'الوصف',
              //   dataIndex: 'desc',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-text">
              //         <span>{record.desc}</span>
              //       </div>
              //     );
              //   }
              // },
              // {
              //   title: 'قبل الخصم',
              //   dataIndex: 'priceBeforeDiscount',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-text">
              //         <span>{record.priceBeforeDiscount}</span>
              //       </div>
              //     );
              //   }
              // },
              {
                title: t('purchaseInvoices.discount'),
                dataIndex: 'discount',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{renderDiscountValue(record)}</span>
                    </div>
                  );
                }
              },
              // {
              //   title: 'سعر الخصم',
              //   dataIndex: 'discount',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-text">
              //         <span>{record.discountPrice}</span>
              //       </div>
              //     );
              //   }
              // },
              {
                title: t('purchaseInvoices.tax'),
                dataIndex: 'tax',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>{record.tax} %</span>
                    </div>
                  );
                }
              },
              {
                title: t('purchaseInvoices.taxPrice'),
                dataIndex: 'totalTax',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>
                        {selectedReceipt?.type == 2 &&
                          record?.totalTax > 0 &&
                          ' - '}
                        {record.totalTax}
                      </span>
                    </div>
                  );
                }
              },
              {
                title: t('purchaseInvoices.total'),
                dataIndex: 'discount',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text">
                      <span>
                        {selectedReceipt?.type == 2 &&
                          record?.totalPrice > 0 &&
                          ' - '}
                        {record.totalPrice}
                      </span>
                    </div>
                  );
                }
              }
            ]}
            // loading={isLoading}
            // scroll={{ x: 400 }}
            footer={false}
          />
        </div>
      );
    }
    return null;
  };

  const renderExtraPrices = () => {
    const addPrices = selectedReceipt.addPrices;
    if (addPrices?.length > 0) {
      return (
        <div className="models-wrap">
          <div
            className="models-title bold-font"
            style={{
              marginBottom: 2,
              fontWeight: 'bold'
            }}
          >
            {t('purchaseInvoices.extraPrices')} :{' '}
          </div>
          <Table
            className="invoice-extra-prices-table"
            bordered
            showHeader={false}
            locale={{
              emptyText: (
                <Empty description={false}>
                  {t('purchaseInvoices.noData')}
                </Empty>
              )
            }}
            pagination={{ defaultPageSize: 5, hideOnSinglePage: true }}
            // className="antd-custom-table"
            dataSource={addPrices.map((item) => ({
              ...item,
              key: item.id
            }))}
            columns={[
              // {
              //   title: 'الكود',
              //   dataIndex: 'id',
              //   render: (_, record) => {
              //     return (
              //       <div className="row-cell row-index bold-font">
              //         <span># {record.id}</span>
              //       </div>
              //     );
              //   }
              // },
              {
                title: t('purchaseInvoices.description'),
                dataIndex: 'desc',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text extra-prices-cell desc-wrap">
                      <span>{record.desc}</span>
                    </div>
                  );
                }
              },
              {
                title: t('purchaseInvoices.price'),
                dataIndex: 'price',
                render: (_, record) => {
                  return (
                    <div className="row-cell row-text extra-prices-cell">
                      <span>{record.price}</span>
                    </div>
                  );
                }
              }
            ]}
            // loading={isLoading}
            // scroll={{ x: 400 }}
            footer={false}
          />
        </div>
      );
    }
    return null;
  };

  const renderInovicePrices = () => {
    const {
      productPrice,
      discountValue,
      discountPrice,
      totalBeforeTax,
      taxPrice,
      totalPrice
    } = selectedReceipt;
    return (
      <div
        className="supplier-details-wrap"
        style={{
          display: 'grid'
          /* width: 'fit-content' */
        }}
      >
        <div
          style={{
            marginBottom: 2,
            fontWeight: 'bold'
          }}
        ></div>
        <div
          style={{
            display: 'grid'
          }}
        >
          {productPrice && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">
                {' '}
                {t('purchaseInvoices.unitsPriceAfterDiscount')} :
              </div>
              <div className="row-value">
                {selectedReceipt?.type == 2 && productPrice > 0 && ' - '}

                {productPrice}
              </div>
            </div>
          )}
          {discountPrice && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">
                {t('purchaseInvoices.discount')} :{' '}
              </div>
              <div className="row-value">{discountPrice}</div>
            </div>
          )}
          {totalBeforeTax && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">
                {t('purchaseInvoices.priceBeforeTax')} :
              </div>
              <div className="row-value">
                {selectedReceipt?.type == 2 && totalBeforeTax > 0 && ' - '}
                {totalBeforeTax}
              </div>
            </div>
          )}
          {taxPrice && (
            <div className="row-wrap" style={rowWrapStyles}>
              <div className="row-label">
                {t('purchaseInvoices.taxPrice')} :
              </div>
              <div className="row-value">
                {selectedReceipt?.type == 2 && taxPrice > 0 && ' - '}
                {taxPrice}
              </div>
            </div>
          )}
        </div>
        {totalPrice && (
          <div
            className="row-wrap"
            style={{
              ...rowWrapStyles,
              fontSize: '18px',
              color: '#000',
              fontWeight: 'bold'
            }}
          >
            <div className="row-label">
              {t('purchaseInvoices.priceAfterTax')} :
            </div>
            <div className="row-value">
              {selectedReceipt?.type == 2 && totalPrice > 0 && ' - '}

              {totalPrice}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderInvoiceHeading = () => {
    if (selectedReceipt?.type == 2) {
      return <p>{t('purchaseInvoices.returnedInvoice')}</p>;
    } else if (selectedReceipt?.type == 1) {
      if (props?.isIncomingInvoice) {
        return t('purchaseInvoices.taxInvoice');
      } else {
        if (selectedReceipt?.user?.taxNumber && selectedReceipt?.supplier) {
          return t('purchaseInvoices.taxPurchaseInvoice');
        } else if (
          !selectedReceipt?.user?.taxNumber &&
          selectedReceipt?.supplier
        ) {
          return t('purchaseInvoices.simplifiedPurchaseInvoice');
        }
        return t('purchaseInvoices.purchaseInvoice');
      }
    }
  };

  // const renderInvoiceHeading = () => {
  //   if (selectedReceipt?.type == 2) {
  //     return <p>{t('purchaseInvoices.returnedInvoice')}</p>;
  //   } else if (selectedReceipt?.type == 1) {
  //     if (props?.isIncomingInvoice) {
  //       return t('purchaseInvoices.taxInvoice');
  //     } else {
  //       if (selectedReceipt?.userOrSupplier == 2 || selectedReceipt?.user) {
  //         return t('purchaseInvoices.simplifiedSaleInvoice');
  //       } else if (
  //         selectedReceipt?.userOrSupplier == 1 ||
  //         selectedReceipt?.supplier
  //       ) {
  //         return t('purchaseInvoices.simplifiedPurchaseInvoice');
  //       }
  //       return t('purchaseInvoices.saleInvoice');
  //     }
  //   }
  // };

  return (
    <div
      className="invoice-details-wrap"
      ref={ref}
      style={{
        padding: '28px 28px',
        paddingTop: 0,
        marginTop: '-16px',
        // direction: 'rtl',
        display: 'grid',
        gap: '16px'
      }}
    >
      <div
        className="img-wrap"
        style={{
          display: 'grid',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: '92px',
          minHeight: '142px'
        }}
      >
        {selectedReceipt?.setting?.image ? (
          <img
            className="system-logo"
            src={selectedReceipt?.setting.image}
            alt="logo"
            style={{
              width: '92px',
              height: '92px'
            }}
          />
        ) : null}
      </div>

      <div
        className="invoice-heading-wrap"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto 1fr',
          gap: 14,
          position: 'relative'
        }}
      >
        {systemSettings?.package_id == 1 && (
          <WaterMark text={t('purchaseInvoices.freeTrial')} />
        )}
        <div className="logo-settings-wrap">
          <div>{renderUser(selectedReceipt?.setting, 1)}</div>
        </div>
        <div
          className="qr-code-label-wrap"
          style={{
            alignSelf: 'end',
            display: 'grid',
            justifyItems: 'center'
          }}
        >
          <QRCode
            value={selectedReceipt?.qrString ? selectedReceipt.qrString : ''}
            width={112}
            height={112}
            renderAs="svg"
          />
          <div
            style={{
              fontSize: 20,
              color: '#000',
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: 12
            }}
          >
            {selectedReceipt?.type == 2 && (
              <p>{t('purchaseInvoices.returnedInvoice')}</p>
            )}
            {selectedReceipt?.type == 1 && (
              <span>{renderInvoiceHeading()}</span>
            )}
            {/* {selectedReceipt?.is_return == 1 ? (
              <p>{t('purchaseInvoices.returnedInvoice')}</p>
            ) : null}
             */}
          </div>
        </div>
        <div
          className="buyer-supplier-wrap"
          style={{
            display: 'grid',
            justifyContent: 'end'
          }}
        >
          {selectedReceipt?.user && renderUser(selectedReceipt?.user, 2)}
          {selectedReceipt?.supplier &&
            renderUser(selectedReceipt?.supplier, 2)}

          <div
            style={{
              display: 'grid'
            }}
          >
            {selectedReceipt?.invoiceNumber && (
              <div className="row-wrap" style={rowWrapStyles}>
                <div className="row-label">
                  {t('purchaseInvoices.invoiceNumber')} :
                </div>
                <div className="row-value">
                  {systemSettings?.invoiceSetting?.change_invoice_num
                    ? selectedReceipt.invoiceNumber
                    : selectedReceipt.manualNum}
                </div>
              </div>
            )}
            {selectedReceipt?.dueDate && (
              <div className="row-wrap" style={rowWrapStyles}>
                <div className="row-label">
                  {t('purchaseInvoices.issuingInvoiceDate')} :
                </div>
                <div className="row-value">{selectedReceipt?.dueDate}</div>
              </div>
            )}
            {selectedReceipt?.date && (
              <div className="row-wrap" style={rowWrapStyles}>
                <div className="row-label">
                  {t('purchaseInvoices.invoiceEntryDate')} :
                </div>
                <div className="row-value">{selectedReceipt?.date}</div>
              </div>
            )}
            {selectedReceipt?.time && (
              <div className="row-wrap" style={rowWrapStyles}>
                <div className="row-label">
                  {t('purchaseInvoices.invoiceTime')} :
                </div>
                <div className="row-value">{selectedReceipt?.time}</div>
              </div>
            )}
          </div>
        </div>
      </div>

      {renderModels()}
      {renderExtraPrices()}
      {renderInovicePrices()}
      {selectedReceipt?.note && (
        <>
          <h3 className="note-modal-box">{t('Quotations.Notes')}</h3>
          <div className="note">
            <p>{selectedReceipt?.note}</p>
          </div>
        </>
      )}

      <div>
        <Descriptions
          title=""
          bordered
          column={1}
          labelStyle={{ fontWeight: 'bold', width: '150px' }}
        >
          {selectedReceipt?.setting?.invoiceSetting?.terms && (
            <Descriptions.Item label={t('SystemSettings.termsAndConditions')}>
              {selectedReceipt?.setting?.invoiceSetting?.terms_text
                ?.toString()
                .split('\r\n')
                .map((item) => (
                  <p>{item}</p>
                ))}
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>

      <div
        style={{
          textAlign: 'center',
          padding: '10px'
        }}
      >
        {selectedReceipt?.setting?.slogan ? selectedReceipt.setting.slogan : ''}
      </div>
    </div>
  );
});

export default ReceiptDetailsComponentV2;
