/* eslint-disable eqeqeq */
import React from 'react';
import QRCode from 'qrcode.react';
import './PosDetailsComponent.scss';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import WaterMark from '../../../common/water-mark/WaterMark';
import { useTranslation } from 'react-i18next';

const PosDetailsComponent = React.forwardRef((props, ref) => {
  const { selectedReceipt } = props;
  const { t } = useTranslation();
  const renderInvoiceHeading = () => {
    if (props?.isIncomingInvoice) {
      return t('Quotations.taxInvoice');
    } else {
      if (
        selectedReceipt?.user?.taxNumber &&
        selectedReceipt?.user
        // selectedReceipt?.userOrSupplier == 2
      ) {
        return t('Quotations.TaxSaleInvoice');
      } else if (
        selectedReceipt?.user
        // selectedReceipt?.userOrSupplier == 1
      ) {
        return t('Quotations.Simplifiedbillofsale');
      }
      return t('Quotations.fiedbillofsale');
    }
  };
  const systemSettings = useSystemSettings();

  const renderProducts = () => {
    return (
      <div className="products-table-total-wrap">
        <table
          className="invoice-products-table"
          style={{
            fontFamily: 'DINNEXTLTARABIC-Regular',
            width: 'calc(100% - 36px)',
            margin: '0 10px',
            padding: 0,
            /* textAlign: 'right', */
            borderCollapse: 'separate',
            borderCpacing: '4px 10px'
          }}
        >
          <thead>
            <tr className="invoice-products-head">
              <th
                style={{
                  minWidth: '100px',
                  padding: '2px',
                  fontSize: '12px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-name-cell"
              >
                {t('Pos.printInvoice.item')}
              </th>
              <th
                style={{
                  padding: '2px',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-quantity-cell"
              >
                {t('Pos.printInvoice.quantity')}
              </th>
              <th
                style={{
                  padding: '2px',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-price-cell"
              >
                {t('Pos.printInvoice.price')}
              </th>
              <th
                style={{
                  padding: '2px',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-price-cell"
              >
                {t('Pos.printInvoice.DiscountValue')}
              </th>
              <th
                style={{
                  padding: '2px',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-price-cell"
              >
                {t('Pos.Main.taxPrice')}
              </th>
              <th
                style={{
                  padding: '2px',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-price-cell"
              >
                {t('Pos.printInvoice.total')}
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedReceipt?.products?.length > 0 &&
              selectedReceipt.products.map((p) => renderInoviceProduct(p))}
          </tbody>
        </table>
        {selectedReceipt.addPrices?.length > 0 &&
          selectedReceipt.addPrices[0].desc &&
          parseFloat(selectedReceipt.addPrices[0].price) > 0 && (
            <table
              className="prices-table"
              style={{
                width: 'calc(100% - 36px)',
                margin: '0 10px',
                padding: 0,
                borderTop: '1px dashed #000',
                /* textAlign: 'right', */
                borderCollapse: 'separate',
                borderSpacing: '4px 8px',
                color: '#000'
              }}
            >
              <tbody>
                {selectedReceipt.addPrices.map((extra, index) =>
                  renderInvoiceExtraPrice(extra, index)
                )}
              </tbody>
            </table>
          )}
        <table
          className="prices-table"
          style={{
            width: 'calc(100% - 36px)',
            borderTop: '1px dashed #000',
            margin: 'auto',
            /*  textAlign: 'right', */
            borderCollapse: 'separate',
            borderSpacing: '4px 8px',
            color: '#000'
          }}
        >
          <tbody>
            {/*  {selectedReceipt.productPrice > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.totalPriceOfProducts')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt?.type == 2 && '-'}
                  {selectedReceipt.productPrice}
                </td>
              </tr>
            )} */}
            {selectedReceipt?.products?.length > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px 4px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.productsNum')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt?.type == 2 &&
                    selectedReceipt?.products.reduce((accumulator, object) => {
                      return accumulator + object.quantity;
                    }, 0) > 0 &&
                    '-'}
                  {selectedReceipt?.products.reduce((accumulator, object) => {
                    return accumulator + object.quantity;
                  }, 0)}
                </td>
              </tr>
            )}
            {parseFloat(selectedReceipt.discountPrice) > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px 4px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.totalDiscount')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt?.type == 2 && '-'}
                  {selectedReceipt.discountPrice}
                </td>
              </tr>
            )}
            {selectedReceipt.additionPrice > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.discountPrice')}:{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt?.type == 2 && '-'}
                  {selectedReceipt.additionPrice}
                </td>
              </tr>
            )}
            {selectedReceipt.totalBeforeTax > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.totalBeforeTax')}:{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt?.type == 2 && '-'}
                  {selectedReceipt.totalBeforeTax}
                </td>
              </tr>
            )}
            {selectedReceipt.taxPrice > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.totalTax')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt?.type == 2 && '-'}
                  {selectedReceipt.taxPrice}
                </td>
              </tr>
            )}
            {selectedReceipt.totalPrice > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.totalPriceAfterTax')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt?.type == 2 && '-'}
                  {selectedReceipt.totalPrice}
                </td>
              </tr>
            )}
            {selectedReceipt.returnPrice &&
              parseFloat(selectedReceipt.returnPrice) > 0 && (
                <tr className="total-before-discount-wrap">
                  <th
                    style={{
                      padding: '2px',
                      width: '182px',
                      fontSize: '13px',
                      color: '#000'
                    }}
                    className="invoice-table-cell col-head"
                  >
                    {t('Pos.printInvoice.returnedAmount')} :{' '}
                  </th>
                  <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                  <td
                    style={{ padding: '2px' }}
                    className="invoice-table-cell discount-value"
                  >
                    {selectedReceipt?.type == 2 && '-'}
                    {selectedReceipt.returnPrice}
                  </td>
                </tr>
              )}
            {selectedReceipt.totalPrice > 0 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Invoices.PaymentMethod')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {/* {selectedReceipt.paymentType == 1 && 'كاش'}
                  {selectedReceipt.paymentType == 2 && 'الكترونى'} */}

                  {selectedReceipt.paymentType == 1 && t('Invoices.cash')}
                  {selectedReceipt.paymentType == 2 && t('Invoices.postpaid')}
                  {selectedReceipt.paymentType == 3 && t('Invoices.visa')}
                  {selectedReceipt.paymentType == 5 &&
                    t('Invoices.bankTransfer')}
                  {selectedReceipt.paymentType == 6 &&
                    t('Invoices.multiplePayment')}
                </td>
              </tr>
            )}
            {selectedReceipt.payments?.length > 0 &&
              selectedReceipt.payments?.map((pay, index) => (
                <tr className="total-before-discount-wrap">
                  <th
                    style={{
                      padding: '2px',
                      width: '182px',
                      fontSize: '13px',
                      color: '#000'
                    }}
                    className="invoice-table-cell col-head"
                  >
                    {`الدفعه رقم ${index + 1} :`}
                  </th>
                  <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                  <td
                    style={{ padding: '2px', color: '#000' }}
                    className="invoice-table-cell discount-value"
                  >
                    {pay?.amount}
                  </td>
                </tr>
              ))}

            {selectedReceipt.paymentType === 2 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Quotations.residual')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt.rest}
                </td>
              </tr>
            )}
            {selectedReceipt.paymentType === 6 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Quotations.cashAmountValue')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt.cash}
                </td>
              </tr>
            )}
            {selectedReceipt.paymentType === 6 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Quotations.visaAmountValue')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedReceipt.visa}
                </td>
              </tr>
            )}
            {/* {selectedReceipt.returnTax &&
              parseFloat(selectedReceipt.returnTax) > 0 && (
                <tr className="total-before-discount-wrap">
                  <th
                    style={{
                      padding: '2px',
                      width: '182px',
                      fontSize: '13px',
                      color: '#000'
                    }}
                    className="invoice-table-cell col-head"
                  >
                    الضريبة المرتجعة :{' '}
                  </th>
                  <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                  <td
                    style={{ padding: '2px' }}
                    className="invoice-table-cell discount-value"
                  >
                    {selectedReceipt.returnTax}
                  </td>
                </tr>
              )} */}
          </tbody>
        </table>
        {/* {invoiceTotalPriceAfterTax > 0 && (
          <div className="grand-total-invoice-price">
            <span>المجموع الكلى</span>
            <span className="bold-font">{invoiceTotalPriceAfterTax}</span>
          </div>
        )} */}

        {/* <div className="qr-code-img-wrap">
          <img className="qrcode-img" src={qrCodeImg} alt="qrcode" />
        </div> */}

        <div
          className="qr-code-img-wrap"
          style={{
            display: 'grid',
            placeItems: 'center',
            padding: '18px',
            paddingTop: '0px',
            marginTop: '8px',
            borderBottom: '1px dashed #000',
            marginBottom: 8
          }}
        >
          <QRCode
            value={selectedReceipt?.qrString ? selectedReceipt.qrString : ''}
            width={92}
            height={92}
            renderAs="svg"
          />
          {/* <QRCode
            value={
              selectedReceipt?.id
                ? `${process.env.REACT_APP_POS_URL}/invoice-details/${String(
                    selectedReceipt.id
                  )}`
                : ''
            }
          /> */}
        </div>
        {selectedReceipt?.setting?.invoiceSetting?.terms && (
          <div
            style={{
              display: 'grid',
              // borderBottom: '1px dashed #000',
              padding: '12px 20px'
            }}
          >
            <div
              style={{
                width: '100%'
              }}
            ></div>
            {selectedReceipt?.setting?.invoiceSetting?.terms_text
              ?.toString()
              .split('\r\n')
              .map((item) => (
                <p>{item}</p>
              ))}
          </div>
        )}
        {selectedReceipt?.note && (
          <>
            <div className="note" style={{ margin: '10px 0' }}>
              <p>{selectedReceipt?.note}</p>
            </div>
          </>
        )}
        <div
          style={{
            textAlign: 'center',
            padding: '0 8px 10px 8px'
          }}
        >
          {/* {systemSettings?.slogan ? systemSettings.slogan : ''} */}
          {selectedReceipt?.setting?.slogan
            ? selectedReceipt.setting.slogan
            : ''}
        </div>
      </div>
    );
  };

  const renderInoviceProduct = (p) => {
    if (p?.adds?.length > 0) {
      return (
        <tr key={p.id} className="invoice-product-li">
          <td
            style={{ padding: '2px', fontSize: '13px', color: '#000' }}
            className="invoice-table-cell product-name-cell"
          >
            {p?.product?.name ? p.product.name : p.name || '----'}
            {p?.adds.map((ing) =>
              ing.name ? (
                <div key={ing.id}> == ( إضافة {ing.name} )</div>
              ) : (
                '---'
              )
            )}
          </td>
          <td
            style={{ padding: '2px', color: '#000' }}
            className="invoice-table-cell product-quantity-cell"
          >
            {p.quantity ? p.quantity : ''}
            {p.adds?.length > 0 &&
              p.adds.map((ing) =>
                ing.quantity ? <div key={ing.id}>{ing.quantity}</div> : '---'
              )}
          </td>
          <td
            style={{ padding: '2px', color: '#000' }}
            className="invoice-table-cell product-price-cell"
          >
            {p.price ? parseFloat(p.price) : '----'}
            {p.adds.map((ing) =>
              String(ing.priceOfUnit) ? (
                <div key={ing.id}>{parseFloat(ing.priceOfUnit)}</div>
              ) : (
                '---'
              )
            )}
          </td>
          <td
            style={{ padding: '2px', color: '#000' }}
            className="invoice-table-cell product-price-cell"
          >
            {p.priceBeforeDiscount ? p.priceBeforeDiscount : '----'}
            {p.adds?.length > 0 &&
              p.adds.map((ing) =>
                String(ing.price) ? (
                  <div key={ing.id}>{parseFloat(ing.price)}</div>
                ) : (
                  '---'
                )
              )}
          </td>
        </tr>
      );
    }
    return (
      <tr key={p.id} className="invoice-product-li">
        <td
          style={{ padding: '2px', color: '#000' }}
          className="invoice-table-cell product-name-cell"
        >
          {p?.product?.name ? p.product.name : p.name || '----'}
        </td>
        <td
          style={{ padding: '2px', color: '#000' }}
          className="invoice-table-cell product-quantity-cell"
        >
          {selectedReceipt?.type == 2 && p.quantity > 0 && '-'}
          {p.quantity ? p.quantity : '---'}
        </td>
        <td
          style={{ padding: '2px', color: '#000' }}
          className="invoice-table-cell product-price-cell"
        >
          {p.price ? parseFloat(p.price) : '----'}
        </td>
        <td
          style={{ padding: '2px', color: '#000' }}
          className="invoice-table-cell product-price-cell"
        >
          {selectedReceipt?.type == 2 &&
            p?.discountPrice &&
            p?.discountPrice > 0 &&
            '-'}
          {p.discountPrice ? p.discountPrice : ''}
        </td>
        <td
          style={{ padding: '2px', color: '#000' }}
          className="invoice-table-cell product-price-cell"
        >
          {selectedReceipt?.type == 2 && p?.totalTax && p?.totalTax > 0 && '-'}
          {p?.totalTax ? parseFloat(p.totalTax) : '----'}
        </td>
        <td
          style={{ padding: '2px', color: '#000' }}
          className="invoice-table-cell product-price-cell"
        >
          {selectedReceipt?.type == 2 &&
            p.totalPrice &&
            p.totalPrice > 0 &&
            '-'}
          {p.totalPrice ? p.totalPrice : ''}
        </td>
      </tr>
    );
  };

  const renderInvoiceExtraPrice = (extraPriceObj, index) => {
    return (
      <tr key={index} className="invoice-product-li">
        <th
          className="invoice-table-cell col-head extra-prices-cell"
          style={{
            fontWeight: 'normal',
            padding: '2px 4px',
            width: '194px',
            color: '#000'
          }}
        >
          {extraPriceObj.desc ? extraPriceObj.desc : ''}
        </th>
        {/* <td className="style={{padding: '2px 4px', , color: '#000', width: '70px'}} fake-td invoice-table-cell">
          ====
        </td> */}
        <td
          style={{ padding: '2px 4px', textAlign: 'center' }}
          className="invoice-table-cell product-quantity-cell extra-prices-cell"
        >
          {selectedReceipt?.type == 2 &&
            extraPriceObj.price &&
            extraPriceObj.price > 0 &&
            '-'}
          {extraPriceObj.price ? extraPriceObj.price : ''}
        </td>
      </tr>
    );
  };

  return (
    <div
      ref={ref}
      className="print-pos-whole-receipt-wrap"
      style={{
        position: 'relative'
      }}
    >
      {/* <p
        style={{
          fontSize: 16,
          color: '#000',
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: 6
        }}
      >
        {selectedReceipt?.setting?.ref_id}
      </p> */}
      {systemSettings?.package_id == 1 && (
        <WaterMark text={t('Quotations.freeTrial')} />
      )}

      <div
        className="pos-receipt-wrap"
        style={{
          fontFamily: 'DINNEXTLTARABIC-Regular',
          height: 'max-content',
          color: '#000'
        }}
      >
        <div
          style={{
            fontSize: 16,
            color: '#000',
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: 6
          }}
        >
          {selectedReceipt?.type == 2 && (
            <p>{t('Quotations.returnedInvoice')}</p>
          )}
          {selectedReceipt?.type == 1 && <span>{renderInvoiceHeading()}</span>}
          {/* {selectedReceipt?.is_return == 1 ? (
            <p> {t('Quotations.returnedInvoice')}</p>
          ) : null}
                      <span>{renderInvoiceHeading()}</span> */}

          {/* <span> - {selectedReceipt?.setting?.id}</span> */}
        </div>
        <div
          className="store-logo-name-wrap"
          style={{
            display: 'grid',
            placeItems: 'center',
            minWidth: '92px',
            minHeight: '92px'
          }}
        >
          {selectedReceipt?.setting?.image ? (
            <img
              className="system-logo"
              src={selectedReceipt?.setting.image}
              alt="logo"
              style={{
                // width: '92px',
                height: '92px'
              }}
            />
          ) : null}

          <div className="store-name">
            {selectedReceipt?.setting?.name
              ? selectedReceipt?.setting.name
              : ''}
          </div>
        </div>

        <div className="invoice-content">
          {/* <div
                className="invoice-number"
                style={{
                  marginTop: '2px'
                }}
              >
                <p
                  className="number-span"
                  style={{
                    padding: '0 4px',
                    textAlign: 'center'
                  }}
                >
                  كود : {selectedReceipt?.id ? selectedReceipt.id : ''}
                </p>
              </div> */}
          <div className="invoice-number">
            <p className="number-span" style={{ color: '#000' }}>
              {t('Quotations.InvoiceNumber')} :{' '}
              {selectedReceipt?.invoiceNumber
                ? '00' + selectedReceipt.invoiceNumber
                : ''}
            </p>
          </div>
          <div
            className="system-info"
            style={{
              margin: '4px 10px',
              paddingBottom: '12px',
              marginBottom: '0',
              borderBottom: '1px dashed #000',
              gap: '12px 4px',
              color: '#000',
              fontSize: '13px'
            }}
          >
            {selectedReceipt?.setting?.address && (
              <div className="cell-wrap address-wrap">
                <span className="info-cell-title">
                  {t('Quotations.Address')} :{' '}
                </span>
                {selectedReceipt?.setting?.address && (
                  <span className="info-cell-value">
                    {selectedReceipt?.setting.address}
                  </span>
                )}
              </div>
            )}

            <div className="cell-wrap vat-wrap">
              <span className="info-cell-title">
                {t('Quotations.VATNumber')} :
              </span>
              <span className="info-cell-value">
                {selectedReceipt?.setting?.tax_number
                  ? selectedReceipt?.setting.tax_number
                  : ''}
              </span>
            </div>
            <div className="cell-wrap vat-wrap">
              <span className="info-cell-title">
                {t('Quotations.CommercialRegistrationNumber')}:{' '}
              </span>
              <span className="info-cell-value">
                {selectedReceipt?.setting?.commercialRecord
                  ? selectedReceipt?.setting.commercialRecord
                  : ''}
              </span>
            </div>
            <div className="date-time-wrap">
              <div className="cell-wrap date-wrap">
                {/* <span className="info-cell-title">التاريخ : </span> */}
                <span className="info-cell-value">
                  {selectedReceipt.date ? selectedReceipt.date : ''}
                </span>
              </div>
              <div className="cell-wrap time-wrap">
                {/* <span className="info-cell-title" style={{ fontSize: '13' }}>
                  الوقت :{' '}
                </span> */}
                <span className="info-cell-value">
                  {selectedReceipt?.time ? selectedReceipt.time : ''}
                </span>
              </div>
            </div>
          </div>

          {renderProducts()}
        </div>
      </div>
    </div>
  );
});

export default PosDetailsComponent;
