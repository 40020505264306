/* eslint-disable eqeqeq */
import React from 'react';
import { Button, Descriptions, Modal, Table, Tooltip, Tabs } from 'antd';
import { PrinterOutlined } from '@ant-design/icons';
import moment from 'moment';
import PosInvoiceContext from '../../contexts/pos-invoice-context/PosInvoiceContext';
import QRCode from 'qrcode.react';
import './SelectedSaleInvoiceModal.scss';
import { useHistory } from 'react-router';
import Links from '../../components/app/Links';
import { useReactToPrint } from 'react-to-print';
import { useRef, useState, useEffect } from 'react';
import Barcode from 'react-barcode';
import { Watermark } from '@hirohe/react-watermark';
import useSystemSettings from '../../custom-hooks/useSystemSettings';
import WaterMark from '../../common/water-mark/WaterMark';
import { useTranslation } from 'react-i18next';
import KitchenDetailsComponent from '../store-pages/store-outgoing-purchase-invoices-page/KitchenDetailsComponent';
const renderDiscountValue = (record) => {
  if (record.discountType == '1') {
    // fixed discount price
    return record.discountPrice;
  } else if (record.discountType == '2') {
    // percentage discount
    return `${record.discountPrice}`;
  } else {
    // no discount
    return 'بدون خصم';
  }
};

const SelectedSaleInvoiceModal = () => {
  const routerLinks = Links();
  const history = useHistory();
  const systemSettings = useSystemSettings();
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState();
  useEffect(() => {
    setCurrentLang(i18n.language);
  }, [i18n.language]);

  const {
    selectedInvoice,
    setSelectedInvoice,
    setSelectedSaleInvoiceModalOpened,
    selectedSaleInvoiceModalOpned
  } = React.useContext(PosInvoiceContext);
  const ref = useRef(null);
  const kitchenRef = useRef(null);
  const handleThePrint = useReactToPrint({
    content: () => ref.current,
    pageStyle: `
    @media print {
      @page {
        size: 200mm auto;
        margin: none;
      }

      .pos-receipt-wrap {
        background-color: #fff;
        border-radius: 10px;
        color: "#000 !important";
        width: 382px;
        margin: 0 auto;
      }

      .store-logo-name-wrap {
        padding-top: 6px;
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .store-name {
        margin-top: 2px;
        color: #000;
        font-size: 18px;
        padding: 0 4px;
        text-align: center;
      }

      .number-span {
        padding: 0 4px;
        text-align: center;
      }
      .invoice-number {
        margin-top: 2px;
      }

      .system-info {
        padding-bottom: 4px;
        margin: 4px;
        margin-bpttom: 0px;
        border-bottom: 1px dashed #000;
        gap: 5px 4px;
        color: #000;
        font-size: 15px;
      }

      .cell-wrap {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 2px;
      }
      .date-time-wrap {
        display: flex;
        justify-content: space-between;
      }
      .date-time-wrap .cell-wrap  {
        margin-left: 28px;
      }
      .info-cell-value {
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px;
        text-overflow: ellipsis;
      }

      .info-cell-title {
        font-size: 12px;
      }
      .products-table-total-wrap,
      .system-info,
      .products-table-total-wa{
        direction:${currentLang == 'ar' ? 'rtl' : 'ltr'};
      }
      
    `
  });
  const handleKitchenPrint = useReactToPrint({
    content: () => kitchenRef.current,
    pageStyle: `
    @media print {
      @page {
        size: 200mm auto;
        margin: none;
      }

      .pos-receipt-wrap {
        background-color: #fff;
        border-radius: 10px;
        color: "#000 !important";
        width: 382px;
        margin: 0 auto;
      }

      .store-logo-name-wrap {
        padding-top: 6px;
        display: flex;
        align-items: center;
        flex-direction: column;
      }

      .store-name {
        margin-top: 2px;
        color: #000;
        font-size: 18px;
        padding: 0 4px;
        text-align: center;
      }

      .number-span {
        padding: 0 4px;
        text-align: center;
      }
      .invoice-number {
        margin-top: 2px;
      }

      .system-info {
        padding-bottom: 4px;
        margin: 4px;
        margin-bpttom: 0px;
        border-bottom: 1px dashed #000;
        gap: 5px 4px;
        color: #000;
        font-size: 15px;
      }

      .cell-wrap {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 2px;
      }
      .date-time-wrap {
        display: flex;
        justify-content: space-between;
      }
      .date-time-wrap .cell-wrap  {
        margin-left: 28px;
      }
      .info-cell-value {
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px;
        text-overflow: ellipsis;
      }

      .info-cell-title {
        font-size: 12px;
      }
    `
  });
  const renderProducts = () => {
    return (
      <div className="products-table-total-wrap">
        <table
          className="invoice-products-table"
          style={{
            fontFamily: 'DINNEXTLTARABIC-Regular',
            width: 'calc(100% - 36px)',
            margin: '0 18px',
            padding: 0,
            /*  textAlign: 'right', */
            borderCollapse: 'separate',
            borderCpacing: '4px 18px'
          }}
        >
          <thead>
            <tr className="invoice-products-head">
              <th
                style={{
                  minWidth: '118px',
                  padding: '2px',
                  fontSize: '12px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-name-cell"
              >
                {t('Pos.printInvoice.item')}
              </th>
              <th
                style={{
                  padding: '2px',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-quantity-cell"
              >
                {t('Pos.printInvoice.quantity')}
              </th>
              <th
                style={{
                  padding: '2px',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-price-cell"
              >
                {t('Pos.printInvoice.price')}
              </th>
              <th
                style={{
                  padding: '2px',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-price-cell"
              >
                {t('Pos.printInvoice.DiscountValue')}
              </th>
              <th
                style={{
                  padding: '2px',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-price-cell"
              >
                {t('Pos.Main.taxPrice')}
              </th>
              <th
                style={{
                  padding: '2px',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  color: '#000'
                }}
                className="invoice-table-cell product-price-cell"
              >
                {t('Pos.printInvoice.total')}
              </th>
            </tr>
          </thead>
          <tbody>
            {selectedInvoice?.products?.length > 0 &&
              selectedInvoice.products.map((p) => renderInoviceProduct(p))}
          </tbody>
        </table>
        {selectedInvoice.addPrices?.length > 0 &&
          selectedInvoice.addPrices[0].desc &&
          parseFloat(selectedInvoice.addPrices[0].price) > 0 && (
            <table
              className="prices-table"
              style={{
                width: 'calc(100% - 36px)',
                margin: '0 18px',
                padding: 0,
                borderTop: '1px dashed #000',
                /* textAlign: 'right', */
                borderCollapse: 'separate',
                borderSpacing: '4px 8px',
                color: '#000'
              }}
            >
              <tbody>
                {selectedInvoice.addPrices.map((extra, index) =>
                  renderInvoiceExtraPrice(extra, index)
                )}
              </tbody>
            </table>
          )}

        <table
          className="prices-table"
          style={{
            width: 'calc(100% - 36px)',
            borderTop: '1px dashed #000',
            margin: 'auto',
            /* textAlign: 'right', */
            borderCollapse: 'separate',
            borderSpacing: '4px 8px',
            color: '#000'
          }}
        >
          <tbody>
            {/* {selectedInvoice.productPrice > 0 &&
              selectedInvoice?.type === 1 && (
                <tr className="total-before-discount-wrap">
                  <th
                    style={{
                      padding: '2px',
                      minWidth: '182px',
                      fontSize: '13px',
                      color: '#000'
                    }}
                    className="invoice-table-cell col-head"
                  >
                    {t('Pos.printInvoice.totalPriceOfProducts')} :
                  </th>
                  <td
                    className="fake-td invoice-table-cell"
                    style={{ padding: '2px', color: '#000', width: '70px' }}
                  ></td>
                  <td
                    style={{ padding: '2px', color: '#000' }}
                    className="invoice-table-cell discount-value"
                  >
                    {selectedInvoice.productPrice}
                  </td>
                </tr>
              )} */}
            {selectedInvoice?.products?.length > 0 &&
              selectedInvoice?.type === 1 && (
                <tr className="total-before-discount-wrap">
                  <th
                    style={{
                      padding: '2px 4px',
                      minWidth: '182px',
                      fontSize: '13px',
                      color: '#000'
                    }}
                    className="invoice-table-cell col-head"
                  >
                    {t('Pos.printInvoice.productsNum')} :{' '}
                  </th>
                  <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                  <td
                    style={{ padding: '2px', color: '#000' }}
                    className="invoice-table-cell discount-value"
                  >
                    {selectedInvoice?.type == 2 &&
                      selectedInvoice?.products.reduce(
                        (accumulator, object) => {
                          return accumulator + object.quantity;
                        },
                        0
                      ) > 0 &&
                      '-'}
                    {selectedInvoice?.products.reduce((accumulator, object) => {
                      return accumulator + object.quantity;
                    }, 0)}
                  </td>
                </tr>
              )}
            {selectedInvoice.discountPrice > 0 &&
              selectedInvoice?.type === 1 && (
                <tr className="total-before-discount-wrap">
                  <th
                    style={{
                      padding: '2px 4px',
                      minWidth: '182px',
                      fontSize: '13px',
                      color: '#000'
                    }}
                    className="invoice-table-cell col-head"
                  >
                    {t('Pos.printInvoice.totalDiscount')} :{' '}
                  </th>
                  <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                  <td
                    style={{ padding: '2px', color: '#000' }}
                    className="invoice-table-cell discount-value"
                  >
                    {selectedInvoice.discountPrice}
                  </td>
                </tr>
              )}

            {selectedInvoice.additionPrice > 0 &&
              selectedInvoice?.type === 1 && (
                <tr className="total-before-discount-wrap">
                  <th
                    style={{
                      padding: '2px',
                      minWidth: '182px',
                      fontSize: '13px',
                      color: '#000'
                    }}
                    className="invoice-table-cell col-head"
                  >
                    {t('Pos.printInvoice.additionalPrices')} :{' '}
                  </th>
                  <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                  <td
                    style={{ padding: '2px', color: '#000' }}
                    className="invoice-table-cell discount-value"
                  >
                    {selectedInvoice.additionPrice}
                  </td>
                </tr>
              )}
            {selectedInvoice.totalBeforeTax > 0 &&
              selectedInvoice?.type === 1 && (
                <tr className="total-before-discount-wrap">
                  <th
                    style={{
                      padding: '2px',
                      minWidth: '182px',
                      fontSize: '13px',
                      color: '#000'
                    }}
                    className="invoice-table-cell col-head"
                  >
                    {t('Pos.printInvoice.totalBeforeTax')} :{' '}
                  </th>
                  <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                  <td
                    style={{ padding: '2px' }}
                    className="invoice-table-cell discount-value"
                  >
                    {selectedInvoice.totalBeforeTax}
                  </td>
                </tr>
              )}

            {selectedInvoice.taxPrice > 0 && selectedInvoice?.type === 1 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {`  ${t('Pos.printInvoice.totalTax')} ${
                    selectedInvoice?.tax > 0 ? `${selectedInvoice?.tax}%` : ''
                  } :`}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedInvoice.taxPrice}
                </td>
              </tr>
            )}
            {selectedInvoice.totalPrice > 0 && selectedInvoice?.type === 1 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.totalPriceAfterTax')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedInvoice.totalPrice}
                </td>
              </tr>
            )}
            {selectedInvoice.returnPrice && selectedInvoice?.type === 2 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.returnedAmount')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedInvoice?.type == 2 && '-'}{' '}
                  {selectedInvoice.totalPrice}
                </td>
              </tr>
            )}
            {selectedInvoice.totalPrice > 0 && selectedInvoice?.type === 1 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.paymentMethod')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedInvoice.paymentType == 1 &&
                    t('purchaseInvoices.cash')}
                  {selectedInvoice.paymentType == 2 &&
                    t('purchaseInvoices.postpaid')}
                  {selectedInvoice.paymentType == 3 &&
                    t('purchaseInvoices.visa')}
                  {selectedInvoice.paymentType == 5 &&
                    t('purchaseInvoices.bankTransfer')}
                  {selectedInvoice.paymentType == 6 &&
                    t('purchaseInvoices.multiplePayment')}
                </td>
              </tr>
            )}
            {selectedInvoice.payments?.length > 0 &&
              selectedInvoice.payments?.map((pay, index) => (
                <tr className="total-before-discount-wrap">
                  <th
                    style={{
                      padding: '2px',
                      width: '182px',
                      fontSize: '13px',
                      color: '#000'
                    }}
                    className="invoice-table-cell col-head"
                  >
                    {`${t('Pos.printInvoice.batchNo')} ${index + 1} :`}
                  </th>
                  <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                  <td
                    style={{ padding: '2px', color: '#000' }}
                    className="invoice-table-cell discount-value"
                  >
                    {pay?.amount}
                  </td>
                </tr>
              ))}

            {selectedInvoice.paymentType === 2 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.residual')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedInvoice.rest}
                </td>
              </tr>
            )}
            {selectedInvoice.paymentType === 6 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.cashAmount')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedInvoice.cash}
                </td>
              </tr>
            )}
            {selectedInvoice.paymentType === 6 && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    width: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.visaAmount')} :{' '}
                </th>
                <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {selectedInvoice.visa}
                </td>
              </tr>
            )}
            {/* {selectedInvoice.returnTax &&
              parseFloat(selectedInvoice.returnTax) > 0 && (
                <tr className="total-before-discount-wrap">
                  <th
                    style={{
                      padding: '2px',
                      width: '182px',
                      fontSize: '13px',
                      color: '#000'
                    }}
                    className="invoice-table-cell col-head"
                  >
                    الضريبة المرتجعة :{' '}
                  </th>
                  <td className="style={{padding: '2px', , color: '#000', width: '70px'}} fake-td invoice-table-cell"></td>
                  <td
                    style={{ padding: '2px' }}
                    className="invoice-table-cell discount-value"
                  >
                    {selectedInvoice.returnTax}
                  </td>
                </tr>
              )} */}
          </tbody>
        </table>

        {/* {invoiceTotalPriceAfterTax > 0 && (
          <div className="grand-total-invoice-price">
            <span>المجموع الكلى</span>
            <span className="bold-font">{invoiceTotalPriceAfterTax}</span>
          </div>
        )} */}

        {/* <div className="qr-code-img-wrap">
          <img className="qrcode-img" src={qrCodeImg} alt="qrcode" />
        </div> */}
        {qrcode === 1 && (
          <div
            className="qr-code-img-wrap"
            style={{
              display: 'grid',
              placeItems: 'center',
              padding: '18px',
              paddingTop: '0px',
              marginTop: '8px',
              marginBottom: 8
            }}
          >
            <QRCode
              value={selectedInvoice?.qrString ? selectedInvoice.qrString : ''}
            />
            {/* <QRCode
            value={
              selectedInvoice?.encryptId
                ? `${process.env.REACT_APP_POS_URL}/invoice-details/${String(
                    selectedInvoice.encryptId
                  )}`
                : ''
            }
          /> */}
          </div>
        )}

        {selectedInvoice?.setting?.invoiceSetting?.terms && (
          <div
            style={{
              display: 'grid',
              borderBottom: '1px dashed rgba(0, 0, 0, 0.1)',
              padding: '12px 20px'
            }}
          >
            <div
              style={{
                width: '100%'
              }}
            ></div>
            {selectedInvoice?.setting?.invoiceSetting?.terms_text
              ?.toString()
              .split('\r\n')
              .map((item) => (
                <p>{item}</p>
              ))}
          </div>
        )}

        <div
          style={{
            textAlign: 'center',
            padding: '0 8px 10px 8px'
          }}
        >
          {/* {systemSettings?.slogan ? systemSettings.slogan : ''} */}
          {selectedInvoice?.setting?.slogan
            ? selectedInvoice.setting.slogan
            : ''}
        </div>
      </div>
    );
  };

  const renderInoviceProduct = (p) => {
    if (p?.materials?.length > 0) {
      return (
        <tr key={p.id} className="invoice-product-li">
          <td
            style={{ padding: '2px', fontSize: '13px', color: '#000' }}
            className="invoice-table-cell product-name-cell"
          >
            {p?.product?.name ? p.product.name : '----'}
            {p?.materials.map((ing) =>
              ing.name ? (
                <div key={ing.id}>
                  {' '}
                  == ( {t('Pos.printInvoice.add')} {ing.name} )
                </div>
              ) : (
                '---'
              )
            )}
          </td>
          <td
            style={{ padding: '2px', color: '#000' }}
            className="invoice-table-cell product-quantity-cell"
          >
            {p.quantity ? p.quantity : '----'}
            {p.materials?.length > 0 &&
              p.materials.map((ing) =>
                ing.quantity ? <div key={ing.id}>{ing.quantity}</div> : '---'
              )}
          </td>
          <td
            style={{ padding: '2px', color: '#000' }}
            className="invoice-table-cell product-price-cell"
          >
            {p.price ? parseFloat(p.price) : '----'}
            {p.materials.map((ing) =>
              String(ing.priceOfUnit) ? (
                <div key={ing.id}>{parseFloat(ing.priceOfUnit)}</div>
              ) : (
                '---'
              )
            )}
          </td>
          <td
            style={{ padding: '2px', color: '#000' }}
            className="invoice-table-cell product-price-cell"
          >
            {p.priceBeforeDiscount ? p.priceBeforeDiscount : '----'}
            {p.materials?.length > 0 &&
              p.materials.map((ing) =>
                String(ing.price) ? (
                  <div key={ing.id}>{parseFloat(ing.price)}</div>
                ) : (
                  '---'
                )
              )}
          </td>
        </tr>
      );
    }
    return (
      <tr key={p.id} className="invoice-product-li">
        <td
          style={{ padding: '2px', color: '#000' }}
          className="invoice-table-cell product-name-cell"
        >
          {p?.product?.name ? p.product.name : '----'}
        </td>
        <td
          style={{ padding: '2px', color: '#000' }}
          className="invoice-table-cell product-quantity-cell"
        >
          {selectedInvoice?.type == 2 && p.quantity && p.quantity > 0 && '-'}
          {p.quantity ? <span>{p.quantity} </span> : ''}
        </td>
        <td
          style={{ padding: '2px', color: '#000' }}
          className="invoice-table-cell product-price-cell"
        >
          {p.price ? <span> {parseFloat(p.price)} </span> : '----'}
        </td>
        <td
          style={{ padding: '2px', color: '#000' }}
          className="invoice-table-cell product-price-cell"
        >
          {p.discountPrice ? (
            <span>
              {' '}
              {selectedInvoice?.type == 2 && p.discountPrice > 0 && '-'}
              {p.discountPrice}{' '}
            </span>
          ) : (
            ''
          )}
        </td>
        <td
          style={{ padding: '2px', color: '#000' }}
          className="invoice-table-cell product-price-cell"
        >
          {p?.totalTax ? (
            <span>
              {selectedInvoice?.type == 2 && p.totalTax > 0 && '-'}
              {p.totalTax}{' '}
            </span>
          ) : (
            ''
          )}
        </td>
        <td
          style={{ padding: '2px', color: '#000' }}
          className="invoice-table-cell product-price-cell"
        >
          {p.totalPrice ? (
            <span>
              {' '}
              {selectedInvoice?.type == 2 && p.totalPrice > 0 && '-'}
              {p.totalPrice}{' '}
            </span>
          ) : (
            ''
          )}
        </td>
      </tr>
    );
  };

  const renderInvoiceExtraPrice = (extraPriceObj, index) => {
    return (
      <tr key={index} className="invoice-product-li">
        <th
          className="invoice-table-cell col-head extra-prices-cell"
          style={{
            fontWeight: 'normal',
            padding: '2px 4px',
            width: '194px',
            color: '#000'
          }}
        >
          {extraPriceObj.desc ? extraPriceObj.desc : ''}
        </th>
        {/* <td className="style={{padding: '2px 4px', , color: '#000', width: '70px'}} fake-td invoice-table-cell">
          ====
        </td> */}
        <td
          style={{ padding: '2px 4px', textAlign: 'center' }}
          className="invoice-table-cell product-quantity-cell extra-prices-cell"
        >
          {extraPriceObj.price ? extraPriceObj.price : ''}
        </td>
      </tr>
    );
  };

  const { barcode, logo, qrcode, tax_num } =
    selectedInvoice?.setting?.invoiceSetting;

  const renderModalBody = () => {
    return (
      // <div className="inovice-details-wrap" ref={ref}>
      //   <div className="inovice-nums-qrcode">
      //     <div className="nums-wrap">
      //       <div className="inovice-num">
      //         <div className="num-title">رقــم الفاتورة : </div>
      //         <div className="num-itself bold-font">
      //           {selectedInvoice?.ShowInvoiceNumber
      //             ? String(selectedInvoice.ShowInvoiceNumber)
      //             : ''}
      //         </div>
      //       </div>
      //       <div className="inovice-dates">
      //         {selectedInvoice?.date && moment(selectedInvoice.date) ? (
      //           <div className="date-wrap">
      //             <div className="date-title">تاريخ اصدار الفاتورة :</div>
      //             <div className="date-value">
      //               {/* {moment(selectedInvoice.date).format('DD-MM-YYYY')} */}
      //               {selectedInvoice.date}
      //             </div>
      //           </div>
      //         ) : null}
      //         {selectedInvoice?.time ? (
      //           <div className="date-wrap">
      //             <div className="date-title">وقت اصدار الفاتورة</div>
      //             <div className="date-value">{selectedInvoice.time}</div>
      //           </div>
      //         ) : null}
      //         {selectedInvoice?.dueDate && moment(selectedInvoice.dueDate) ? (
      //           <div className="date-wrap">
      //             <div className="date-title">تاريخ التوريد :</div>
      //             <div className="date-value">{selectedInvoice.dueDate}</div>
      //           </div>
      //         ) : null}
      //       </div>
      //     </div>
      //     <div className="qr-code-wrap">
      //       {/* <img className="qr-code-img" src={qrcodeImg} alt="qrcode" /> */}
      //       <QRCode
      //         value={selectedInvoice?.qrString ? selectedInvoice.qrString : ''}
      //       />
      //       {/* <QRCode
      //         value={
      //           selectedInvoice?.encryptId
      //             ? `${process.env.REACT_APP_POS_URL}/invoice-details/${String(
      //                 selectedInvoice.encryptId
      //               )}`
      //             : ''
      //         }
      //       /> */}
      //     </div>
      //   </div>

      //   {selectedInvoice?.user && renderClient()}
      //   {selectedInvoice?.products?.length > 0 && renderModels()}
      //   {renderExtraPrices()}
      //   {renderInovicePrices()}
      // </div>
      <div style={{ width: '100%' }}>
        <div className="print-invoice-page">
          <div ref={ref} className="print-pos-whole-receipt-wrap">
            <div
              className="pos-receipt-wrap"
              style={{
                fontFamily: 'DINNEXTLTARABIC-Regular',
                height: 'max-content',
                color: '#000'
              }}
            >
              {systemSettings?.package_id == 1 && (
                <WaterMark text={t('Pos.printInvoice.freeTrial')} />
              )}

              {/* <p
              style={{
                fontSize: 16,
                color: '#000',
                fontWeight: 'bold',
                textAlign: 'center',
                marginBottom: 6
              }}
            >
              {selectedInvoice?.setting?.ref_id}
            </p> */}
              <p
                style={{
                  fontSize: 16,
                  color: '#000',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginBottom: 6
                }}
              >
                {selectedInvoice?.type == 2 ? (
                  <p>{t('purchaseInvoices.returnedInvoice')}</p>
                ) : (
                  selectedInvoice?.type == 1 && (
                    <span>
                      {selectedInvoice?.user?.taxNumber
                        ? t('Pos.printInvoice.taxInvoice')
                        : t('Pos.printInvoice.simpleTaxInvoice')}
                    </span>
                  )
                )}

                {/* <span> {selectedInvoice?.setting?.ref_id}</span> */}
              </p>
              <div className="store-logo-name-wrap">
                {logo === 1 && (
                  <img
                    src={
                      selectedInvoice?.setting?.image
                        ? selectedInvoice?.setting.image
                        : '/assets/imgs/logo/logo-dark.svg'
                    }
                    alt="Logo"
                    style={{ width: '122px' }}
                  />
                )}
                <h2 className="store-name">
                  {selectedInvoice?.setting?.name
                    ? selectedInvoice?.setting.name
                    : ''}
                </h2>
                {/* <div className="store-number">
                رقم الفاتورة
                {selectedInvoice?.ShowInvoiceNumber
                  ? ` ${selectedInvoice.ShowInvoiceNumber} `
                  : ''}
              </div> */}

                {/* <div
                className="store-name"
                style={{
                  fontSize: 18
                }}
              >
                {renderType(selectedInvoice?.type)}
              </div> */}
                {barcode === 1 && (
                  <>
                    <Barcode
                      format="CODE128"
                      value={selectedInvoice.ShowInvoiceNumber}
                      height={50}
                      fontSize={15}
                      // width={3}
                    />
                  </>
                )}
                {/* <div>
                {selectedInvoice.products.map((p) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '10px'
                    }}
                  >
                    <hr
                      style={{
                        width: '100%',
                        height: '1px',
                        backgroundColor: '#000'
                      }}
                    />
                    <div
                      style={{
                        minWidth: '100px',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        gap: '15px'
                      }}
                    >
                      <span style={{ marginLeft: '10px' }}>
                        {p.product.name}
                      </span>
                      <span>SR</span>
                      <span>{p.price}</span>
                    </div>
                  </div>
                ))}
              </div> */}
                {/* <table>
                <tbody>
                  {selectedInvoice.products.map((p) => (
                    <>
                      <tr style={{ borderBottom: '1px solid #000' }}></tr>
                      <tr key={p.id} className="invoice-product-li">
                        <td
                          style={{
                            padding: '2px',
                            fontSize: '13px',
                            color: '#000',
                            paddingLeft: '10px'
                          }}
                          className="invoice-table-cell product-name-cell"
                        >
                          {p.product.name}
                        </td>
                        <td
                          style={{
                            padding: '2px',
                            fontSize: '13px',
                            color: '#000',
                            paddingLeft: '10px'
                          }}
                          className="invoice-table-cell product-name-cell"
                        >
                          {p.price}
                        </td>
                        <td
                          style={{
                            padding: '2px',
                            fontSize: '13px',
                            color: '#000'
                          }}
                          className="invoice-table-cell product-name-cell"
                        >
                          SR
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table> */}
              </div>
              <div className="invoice-content">
                {/* <div
                className="invoice-number"
                style={{
                  marginTop: '2px'
                }}
              >
                <p
                  className="number-span"
                  style={{
                    padding: '0 4px',
                    textAlign: 'center'
                  }}
                >
                  كود : {selectedInvoice?.id ? selectedInvoice.id : ''}
                </p>
              </div> */}

                <div className="invoice-number">
                  {/* <p className="number-span" style={{ color: '#000' }}>
                  رقم الفاتورة :
                  {selectedInvoice?.invoiceNumber
                    ? ` 00${selectedInvoice.invoiceNumber} `
                    : ''}
                </p> */}
                </div>

                <div
                  className="system-info"
                  style={{
                    margin: '4px 18px',
                    paddingBottom: '12px',
                    marginBottom: '0',
                    borderBottom: '1px dashed #000',
                    gap: '12px 4px',
                    color: '#000',
                    fontSize: '15px'
                  }}
                >
                  {selectedInvoice?.shift?.pos?.address && (
                    <div className="cell-wrap address-wrap">
                      <span className="info-cell-title">
                        {' '}
                        {t('Pos.printInvoice.shopAddress')} : :{' '}
                      </span>
                      {selectedInvoice?.shift?.pos?.address && (
                        <span className="info-cell-value">
                          {selectedInvoice?.shift?.pos.address}
                        </span>
                      )}
                    </div>
                  )}
                  <div className="cell-wrap vat-wrap">
                    <span className="info-cell-title">
                      {t('Pos.printInvoice.taxNumbe2')} :{' '}
                    </span>{' '}
                    <span className="info-cell-value">
                      {tax_num ? tax_num : ''}
                      {/* {systemSettings?.tax_number
                        ? systemSettings?.tax_number
                        : ''} */}
                    </span>
                  </div>
                  <div className="cell-wrap vat-wrap">
                    <span className="info-cell-title">
                      {t('Pos.printInvoice.commercialRecord')} :{' '}
                    </span>
                    <span className="info-cell-value">
                      {selectedInvoice?.setting?.commercialRecord
                        ? selectedInvoice?.setting?.commercialRecord
                        : ''}
                    </span>
                  </div>
                  <div className="date-time-wrap">
                    <div className="cell-wrap date-wrap">
                      {/* <span className="info-cell-title">التاريخ : </span> */}
                      <span className="info-cell-value">
                        {selectedInvoice.date ? selectedInvoice.date : ''}
                      </span>
                    </div>
                    <div className="cell-wrap time-wrap">
                      {/* <span
                      className="info-cell-title"
                      style={{ fontSize: '13' }}
                    >
                      الوقت :{' '}
                    </span> */}
                      <span className="info-cell-value">
                        {selectedInvoice?.time ? selectedInvoice.time : ''}
                      </span>
                    </div>
                  </div>

                  {selectedInvoice?.note && (
                    <div className="cell-wrap vat-wrap">
                      <span className="info-cell-title">
                        {t('Pos.printInvoice.saleNotes')} :{' '}
                      </span>{' '}
                      <span className="info-cell-value">
                        {selectedInvoice?.note}
                      </span>
                    </div>
                  )}
                </div>

                {selectedInvoice.user && renderClient()}

                {renderProducts()}
              </div>
            </div>
          </div>
        </div>
        {/* <InvoiceA4Render selectedInvoice={selectedInvoice} ref={a4Ref} /> */}
      </div>
    );
  };

  const renderClient = () => {
    const {
      user: { name, phone, email, address, taxNumber }
    } = selectedInvoice;
    return (
      // <div className="supplier-details-wrap">
      //   <Descriptions title="العميـــل" bordered column={1}>
      //     {name && (
      //       <Descriptions.Item label="الإســـم">{name}</Descriptions.Item>
      //     )}
      //     {phone && (
      //       <Descriptions.Item label="رقم الهاتف">{phone}</Descriptions.Item>
      //     )}
      //     {email && (
      //       <Descriptions.Item label="البريد الاكترونى">
      //         {email}
      //       </Descriptions.Item>
      //     )}
      //     {address && (
      //       <Descriptions.Item label="العنوان">{address}</Descriptions.Item>
      //     )}
      //   </Descriptions>
      // </div>
      <div className="products-table-total-wa">
        <table
          className="prices-table"
          style={{
            width: 'calc(100% - 36px)',
            borderTop: '1px dashed #000',
            margin: 'auto',
            textAlign: `${currentLang == 'ar' ? 'right' : 'left'}`, 
            borderCollapse: 'separate',
            borderSpacing: '4px 8px',
            color: '#000',
            borderBottom: '1px dashed rgb(0, 0, 0)'
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  padding: '2px',
                  minWidth: '182px',
                  fontSize: '15px',
                  color: '#000'
                }}
                className="invoice-table-cell col-head"
              >
                {t('Pos.printInvoice.customerData')}
              </th>
            </tr>
          </thead>
          <tbody>
            {name && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.name')} :
                </th>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {name}
                </td>
              </tr>
            )}
            {email && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px 4px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.email')} :
                </th>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {email}
                </td>
              </tr>
            )}
            {phone && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.phone')} :
                </th>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {phone}
                </td>
              </tr>
            )}
            {address && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.address')} :
                </th>
                <td
                  style={{ padding: '2px' }}
                  className="invoice-table-cell discount-value"
                >
                  {address}
                </td>
              </tr>
            )}
            {taxNumber && (
              <tr className="total-before-discount-wrap">
                <th
                  style={{
                    padding: '2px',
                    minWidth: '182px',
                    fontSize: '13px',
                    color: '#000'
                  }}
                  className="invoice-table-cell col-head"
                >
                  {t('Pos.printInvoice.taxNumber')} :
                </th>
                <td
                  style={{ padding: '2px', color: '#000' }}
                  className="invoice-table-cell discount-value"
                >
                  {taxNumber}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <Modal
      className="shared-custom-modal selected-inovice-modal"
      width="94%"
      style={{ maxWidth: '1142px' }}
      title={
        <>
          {t('Pos.printInvoice.Salesinvoicedetails')}{' '}
          {selectedInvoice?.is_return == 2 && t('Invoices.isReturnd')}
          <Tooltip title={t('Pos.printInvoice.printSaleInvoice')}>
            <Button
              onClick={handleThePrint}
              className="circled-btn"
              type="dashed"
              shape="circle"
              icon={<PrinterOutlined />}
            />
          </Tooltip>
          <Tooltip title={t('Pos.printInvoice.printKitckenInvoice')}>
            <Button
              onClick={handleKitchenPrint}
              // onClick={() =>
              //   history.push(
              //     routerLinks.generalInvoiceDetailsPage(
              //       selectedInvoice?.encryptId
              //     )
              //   )
              // }
              className="circled-btn"
              type="dashed"
              shape="circle"
              icon={<PrinterOutlined />}
            />
          </Tooltip>
        </>
      }
      visible={selectedSaleInvoiceModalOpned}
      onOk={() => {
        setSelectedSaleInvoiceModalOpened(false);
        setSelectedInvoice(null);
      }}
      onCancel={() => {
        setSelectedSaleInvoiceModalOpened(false);
        setSelectedInvoice(null);
      }}
      footer={false}
    >
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Sale Invoice" key="1" forceRender={true}>
          {renderModalBody()}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Kitchen Invoice" key="2" forceRender={true}>
          <KitchenDetailsComponent
            systemSettings={systemSettings}
            ref={kitchenRef}
            selectedReceipt={selectedInvoice}
          />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default SelectedSaleInvoiceModal;
