import { Button, Popover, Tooltip } from 'antd';
import countWords from '../../utils/countWords';
import trimWords from '../../utils/trimWords';
import { ReadOutlined } from '@ant-design/icons';
import { EyeOutlined } from '@ant-design/icons';
import moment from 'moment';

const tableColumns = (
  allFetchedShiftReports,
  setFetchCountSaleInvoices,
  setSelectedShiftReport,
  setIsLoadingAllSaleInvoices,
  setSelectedShiftReportsModalOpened,
  t,
  token
) => {
  const handleViewReceiptClick = (record) => {
    debugger
    console.log(allFetchedShiftReports);
      const foundInvoice = allFetchedShiftReports.find(
        (receipt) => receipt.id === record.id
      );
      if (foundInvoice) {
        setSelectedShiftReport(record);
        setSelectedShiftReportsModalOpened(true);
      }
  };

  return [
    {
      title: t('ShiftReports.salePointName'),
      dataIndex: 'name',
      // width: 62
      render: (_, record) => {
        return (
          <div className="row-cell row-index bold-font">
            <span>{`${record?.pos?.name ? record.pos.name : '----'}`}</span>
          </div>
        );
      }
    },
    {
      title: t('ShiftReports.employeeName'),
      dataIndex: 'employee',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.employee?.name ? record?.employee?.name : 'بدون موظف'}
          </div>
        );
      }
    },

    {
      title: t('ShiftReports.cashSales'),
      dataIndex: 'shiftCash',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.shiftCash ? record?.shiftCash : '----'}
          </div>
        );
      }
    },
    {
      title: t('ShiftReports.visaSales'),
      dataIndex: 'shiftVisa',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.shiftVisa ? record?.shiftVisa : '----'}
          </div>
        );
      }
    },
    {
      title: t('ShiftReports.bankTransfer'),
      dataIndex: 'shiftVisa',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.shiftBank ? record?.shiftBank : '----'}
          </div>
        );
      }
    },
    {
      title: t('ShiftReports.deferred'),
      dataIndex: 'shiftVisa',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.shiftDeferred ? record?.shiftDeferred : '----'}
          </div>
        );
      }
    },

    {
      title: t('ShiftReports.box'),
      dataIndex: 'shiftMoney',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.shiftMoney ? record.shiftMoney : '----'}
          </div>
        );
      }
    },
    {
      title: t('ShiftReports.total'),
      dataIndex: 'totalAmount',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.totalAmount ? record.totalAmount : '----'}
          </div>
        );
      }
    },
    {
      title: t('ShiftReports.startDate'),
      dataIndex: 'start_shift',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.start_shift
              ? moment(record.start_shift).format('YYYY-MM-DD | HH:MM a')
              : '----'}
          </div>
        );
      }
    },
    {
      title: t('ShiftReports.expiryDate'),
      dataIndex: 'end_shift',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.end_shift
              ? moment(record.end_shift).format('YYYY-MM-DD | HH:MM a')
              : '----'}
          </div>
        );
      }
    },
    // {
    //   title: t('ShiftReports.firstInvoiceNumber'),
    //   dataIndex: 'first_invoice_number',
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         {record?.first_invoice_number
    //           ? record?.first_invoice_number
    //           : '----'}
    //       </div>
    //     );
    //   }
    // },
    // {
    //   title: t('ShiftReports.lastInvoiceNumber'),
    //   dataIndex: 'last_invoice_number',
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-text">
    //         {record?.last_invoice_number ? record?.last_invoice_number : '----'}
    //       </div>
    //     );
    //   }
    // },
    {
      title: t('ShiftReports.invoicesNumber'),
      dataIndex: 'invoices_count',
      render: (_, record) => {
        return (
          <div className="row-cell row-text">
            {record?.invoices_count ? record?.invoices_count : '----'}
          </div>
        );
      }
    },
    {
      title: t('ShiftReports.view'),
      render: (_, record) => {
        return (
          <div className="row-cell row-circled-btn">
            <Tooltip title={t('ShiftReports.view')}>
              <Button
                className="circled-btn"
                type="dashed"
                shape="circle"
                icon={<EyeOutlined />}
                onClick={() => handleViewReceiptClick(record)}
              />
            </Tooltip>
          </div>
        );
      }
    }
  ];
};

export default tableColumns;
