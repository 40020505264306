import { yupResolver } from '@hookform/resolvers/yup';
import { DatePicker, Form } from 'antd';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import getBalanceSheetDataApi from '../../../apis/accounting-apis/accounting-details-apis/getBalanceSheetDataApi';
import excelImg from '../../../assets/imgs/icons/excel-btn.png';
import AntdSelectOption from '../../../common/antd-form-components/AntdSelectOption';
import CalendarIcon from '../../../common/icons/CalendarIcon';
import BalanceSheetContext from '../../../contexts/accounting-context/BalanceSheetContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../../custom-hooks/useCustomApiRequest';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import errorNotification from '../../../utils/errorNotification';
import successNotification from '../../../utils/successNotification';
import './TableFilter.scss';
// import './TableFilter.scss';

const TableFilter = (props) => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const {
    setIsLoading,
    setFetchedBalanceSheetDetails,
    setFetchCount,
    setAccountsListId,
    tableFilter,
    setTableFilter,
  } = useContext(BalanceSheetContext);
  const date = new Date();
  const [schema, setSchema] = useState();
  const { RangePicker } = DatePicker;
  const [selectedPicker, setSelectedPicker] = React.useState();
  const customApiRequest = useCustomApiRequest();
  const systemSettings = useSystemSettings();
  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      account_id: '',
      // from_date: '',
      // to_date: ''
    }
  });
  const handlePickerChange = (value, string) => {
    setSelectedPicker({
      moments: value,
      strings: string
    });
  };
  // React.useEffect(() => {
  //   if (selectedPicker?.strings?.length > 0) {
  //     setSelectedPicker((prev) => ({
  //       ...prev,
  //       from: selectedPicker.strings[0],
  //       to: selectedPicker.strings[1]
  //     }));
  //   }
  // }, [selectedPicker?.strings]);

  useEffect(() => {
    setTableFilter((prev) => ({
      ...prev,
      from: selectedPicker?.strings[0] || moment("2024-01-01").format("YYYY-MM-DD"),
      to: selectedPicker?.strings[1] || moment(date).format("YYYY-MM-DD")
    }));
  }, [selectedPicker?.strings]);



  useEffect(() => {
    setSchema(
      Yup.object().shape({
        account_id: Yup.string().required(t('ProfessorBook.EnterAccountName')),
        // from_date: Yup.string().required('ادخل تاريخ البداية'),
        // to_date: Yup.string().required('ادخل تاريخ النهاية')
        // from_date: Yup.string().required('ادخل تاريخ البداية'),
        // to_date: Yup.string().required('ادخل تاريخ النهاية')
      })
    );
  }, []);

  const onSubmit = async (data) => {
    setIsLoading(true);
    let api, successMessage, errorMessage;
    successMessage = t('Messages.ledgerDisplayed');
    errorMessage = t('Messages.showAccountError');
    api = getBalanceSheetDataApi(user?.token, {...data , ...tableFilter});
    customApiRequest(
      api,
      (res) => {
        setIsLoading(false);
        if (res?.data?.data) {
          console.log("//%%",res?.data?.data);
          setFetchCount((prevState) => prevState + 1);
          setFetchedBalanceSheetDetails(res?.data?.data);
          successNotification({
            title:t('Messages.addedSuccessfully'),
            message: successMessage
          });
        } else {
          //   setIsLoading(false);
          errorNotification({
            title: t('Messages.ErrorOccurred'),
            message: res?.data?.message || errorMessage
          });
        }
      },
      (error) => {
        setIsLoading(false);
        // setIsLoading(false);
        errorNotification({
          title: t('Messages.ErrorOccurred'),
          message: error?.response?.data?.message || t('Messages.pleaseTryLater')
        });
      }
    );
  };

  const handleChange = (value) => {
    setAccountsListId(value);
  };

  const [form] = Form.useForm();
  return (
    <div className="balance-filter-form">
      <Form
        className="accounting-form filter-form receipt-filter-form"
        form={form}
        // layout="vertical"
        onFinish={handleSubmit(onSubmit)}
      >
        <div className="select-label-wrap">
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name="account_id"
              errorMsg={errors?.account_id && errors.account_id.message}
              validateStatus={errors?.account_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder={t('ProfessorBook.AccountName')}
              onChange={(e) => handleChange(e)}
              options={
                props?.allAccounts?.length &&
                props?.allAccounts?.map((acc) => {
                  return {
                    title: `${acc.holder}`,
                    value: `${acc.id}`
                  };
                })
              }
              formClassName="accounting-form"
            />
          </div>
        </div>
        {/* <div className="date-picker-wrap">
          <CustomAntdDatePicker
            // disablePast
            setValue={setValue}
            placeholder={'تاريخ البداية'}
            control={control}
            name="from_date"
            className="receipt-date-picker"
            formClassName="accounting-form"
            defaultValue={moment(`2023-01-01`)}
          />
          {errors?.from_date && (
            <p className="error-p">{errors.from_date.message}</p>
          )}
        </div>
        <div className="date-picker-wrap">
          <CustomAntdDatePicker
            // disablePast
            setValue={setValue}
            placeholder={'تاريخ النهاية'}
            control={control}
            name="to_date"
            className="receipt-date-picker"
            formClassName="accounting-form"
            defaultValue={moment(date)}
          />
          {errors?.to_date && (
            <p className="error-p">{errors.to_date.message}</p>
          )}
        </div> */}
        <div className="range-picker-wrap">
            <RangePicker
              value={selectedPicker?.moments && selectedPicker.moments}
              onChange={handlePickerChange}
              // format="YYYY-MM-DD"

              className={` !h-[62px] filter-range-picker`}
              allowClear={true}
            style={{ height:"62px"}}
              suffixIcon={<CalendarIcon />}
              placeholder={[t('ProfessorBook.dateFrom'), t('ProfessorBook.dateTo')]}
              defaultValue={[moment("2024-01-01"), moment(date)]}
              formClassName="accounting-form"
            />
        </div>
        <button className="filter-submit-btn" type="submit">
          {t('ProfessorBook.Display')}
        </button>
      </Form>
      {watch('account_id') && (
        <a
          href={`${
            process.env.REACT_APP_BASE_URL
          }/Excel/exportLedger/${watch('account_id')}?client_id=${
            systemSettings?.id
          }&from_date=${
            tableFilter?.from
              ? tableFilter?.from
              : moment("2023-01-01").format('YYYY-MM-DD')
          }&to_date=${
            tableFilter?.to
              ? tableFilter?.to
              : moment(date).format('YYYY-MM-DD')
          }`}
          download
          className="excel-export-btn"
        >
          <img
            style={{ height: '61px' }}
            src={excelImg}
            alt="export to excel"
          />
        </a>
      )}
    </div>
  );
};

export default TableFilter;
