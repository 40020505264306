/* eslint-disable react-hooks/exhaustive-deps */
import { Descriptions } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import getAllIncomingPurchaseInvoicesApi from '../../../apis/store-apis/incoming-purchas-invoices-apis/getAllIncomingPurchaseInvoicesApi';
import OutgoingPurchaseInvoiceContext from '../../../contexts/outgoing-purchase-invoice-context/OutgoingPurchaseInvoiceContext';
import UserContext from '../../../contexts/user-context/UserProvider';
import useSystemSettings from '../../../custom-hooks/useSystemSettings';
import PageModal from './PageModal';
import PageModalForProducts from './PageModalForProducts';
import PageTable from './PageTable';
import SelectedReceiptModal from './SelectedReceiptModal';
import SelectedReceiptModalProducts from './SelectedReceiptModalProducts';
import SelectedReceiptModalProductsReport from './SelectedReceiptModalProductsReport';
import './sharedOutgoingPurchaseInvoice.scss';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Cookies from 'js-cookie';
const SharedOutgoingPurchaseInvoice = ({
  allFetchedReceipts,
  withoutReturnBtn = false,
  total,
  isReportsPage,
  taxPage,
  selectedPicker,
  flag
}) => {
  const systemSettings = useSystemSettings();
  const { pathname } = useLocation();
  const { modalOpened, selectedReceiptModalOpened, tableFilter } = useContext(
    OutgoingPurchaseInvoiceContext
  );

  const { user } = useContext(UserContext);
  const [totalPrice, setTotalPrice] = useState(0);
  const user_id_from_payment = pathname?.split('/')[3];
  const { t } = useTranslation();

  useEffect(() => {
    const getData = async () => {
      try {
        const fetchData = await getAllIncomingPurchaseInvoicesApi(user?.token, {
          invoice_type: 2,
          ...tableFilter,
          page: 1,
          user_id_from_payment: user_id_from_payment,
          type: 2
        });
        if (fetchData?.status === 200 && fetchData?.data?.data) {
          setTotalPrice(fetchData?.data?.data?.total);
        }
      } catch (error) {}
    };
    getData();
  }, [allFetchedReceipts, user?.token]);

  const renderSystemSettings = (obj) => {
    const {
      name,
      phone,
      email,
      addressCode,
      buildingNum,
      streetName,
      district,
      city,
      anotherId,
      country,
      VATRegistrationNumber,
      tax_number,
      postalCode
    } = obj;
    return (
      <div
        style={{
          marginTop: 32
        }}
        className="system-settings-details-wrap"
      >
        <Descriptions title="" bordered column={2}>
          {name && (
            <Descriptions.Item label={t('salesInvoiceReport.name')}>
              {name}
            </Descriptions.Item>
          )}
          {phone && (
            <Descriptions.Item label={t('salesInvoiceReport.phone')}>
              {phone}
            </Descriptions.Item>
          )}
          {email && (
            <Descriptions.Item label={t('salesInvoiceReport.email')}>
              {email}
            </Descriptions.Item>
          )}
          {buildingNum && (
            <Descriptions.Item label={t('salesInvoiceReport.buildingNumber')}>
              {buildingNum}
            </Descriptions.Item>
          )}
          {streetName && (
            <Descriptions.Item label={t('salesInvoiceReport.streetName')}>
              {streetName}
            </Descriptions.Item>
          )}
          {district && (
            <Descriptions.Item label={t('salesInvoiceReport.district')}>
              {district}
            </Descriptions.Item>
          )}
          {city && (
            <Descriptions.Item label={t('salesInvoiceReport.city')}>
              {city}
            </Descriptions.Item>
          )}
          {country && (
            <Descriptions.Item label={t('salesInvoiceReport.country')}>
              {country}
            </Descriptions.Item>
          )}
          {postalCode && (
            <Descriptions.Item label={t('salesInvoiceReport.postalCode')}>
              {postalCode}
            </Descriptions.Item>
          )}
          {addressCode && (
            <Descriptions.Item label={t('salesInvoiceReport.addressCode')}>
              {addressCode}
            </Descriptions.Item>
          )}
          {VATRegistrationNumber && (
            <Descriptions.Item label={t('salesInvoiceReport.vatNumber')}>
              {VATRegistrationNumber}
            </Descriptions.Item>
          )}
          {tax_number && (
            <Descriptions.Item label={t('salesInvoiceReport.vatNumber')}>
              {tax_number}
            </Descriptions.Item>
          )}
          {anotherId && (
            <Descriptions.Item label={t('salesInvoiceReport.anotherId')}>
              {anotherId}
            </Descriptions.Item>
          )}
          {totalPrice && (
            <Descriptions.Item label={t('salesInvoiceReport.totalPrice')}>
              {totalPrice} {t('salesInvoiceReport.sar')}
            </Descriptions.Item>
          )}
          {/* {String(total) && (
            <Descriptions.Item label="السعر الإجمالى">
              {total} ريـــال
            </Descriptions.Item>
          )} */}
          {/* {String(allFetchedReceipts?.total) && (
            <Descriptions.Item label="السعر الإجمالى">
              {allFetchedReceipts?.total} ريـــال
            </Descriptions.Item>
          )} */}
        </Descriptions>
      </div>
    );
  };

  const handleModal = (flag) => {
    if (flag === '1') {
      return <SelectedReceiptModalProductsReport />;
    } else if (flag === '3') {
      return <SelectedReceiptModalProducts />;
    }
  };
  const HandleRenderSystemSettings = (user, flag) => {
    if (user === 1) {
      if (flag === '3') {
        return renderSystemSettings(systemSettings);
      } else if (flag !== '3' && flag !== '1') {
        return renderSystemSettings(systemSettings);
      }
    } else if (user === 2) {
      return renderSystemSettings(systemSettings);
    }
  };

  return (
    <>
      {withoutReturnBtn &&
        systemSettings &&
        HandleRenderSystemSettings(user.subscriptionType, flag)}
      <div className="PageTable-wrapper">
        <PageTable
          withoutReturnBtn={withoutReturnBtn}
          allFetchedReceipts={allFetchedReceipts}
          isReportsPage={isReportsPage}
          taxPage={taxPage}
          selectedPicker={selectedPicker}
          tab={flag}
        />
      </div>

      {modalOpened && user.subscriptionType === 1 && <PageModalForProducts />}
      {modalOpened && user.subscriptionType === 2 && <PageModal />}

      {selectedReceiptModalOpened &&
        (user.subscriptionType === 1 ? (
          handleModal(flag)
        ) : (
          <SelectedReceiptModal systemSettings={systemSettings} />
        ))}
    </>
  );
};

export default SharedOutgoingPurchaseInvoice;
