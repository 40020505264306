import { Button, Popconfirm, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import SyncSallaApi from '../../apis/fatoorahStore-api/SyncSallaApi';
import deleteListIntegrationApi from '../../apis/fatoorahStore-api/deleteListIntegrationApi';
import DeleteIcon from '../../common/icons/DeleteIcon';
import EditIcon from '../../common/icons/EditIcon';
import errorNotification from '../../utils/errorNotification';
import successNotification from '../../utils/successNotification';
import AuthWithStore from '../../apis/fatoorahStore-api/AuthWithStore';
import { useState } from 'react';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';

const TableColumns = (
  stores,
  setModalOpened,
  setFetchCount,
  setSelectedClient,
  setIsLoading,
  token,
  sallaToken
) => {
  const { t } = useTranslation();
  const [btnIsLoading, setBtnIsLoading] = useState(false);
  const customApiRequest = useCustomApiRequest();
  const handleAuthStore = async (type) => {
    customApiRequest(
      AuthWithStore(token, type),
      (res) => {
        setBtnIsLoading(true);
        if (res?.data?.status === 'failed' || res?.data?.status === false) {
          errorNotification({
            title: t('Messages.linkingError'),
            message: t('Messages.incorrectData')
          });
        } else if (res?.data?.status === 'success') {
          let url = res?.data?.url;
          window.open(url, '_blank', 'noreferrer');
          setBtnIsLoading(false);
          // store.addNotification({
          //   title: 'تم الربط بنجاح',
          //   message: "---------",
          //   type: 'success',
          //   insert: 'top',
          //   container: 'top-right',
          //   animationIn: ['animate__animated', 'animate__fadeIn'],
          //   animationOut: ['animate__animated', 'animate__fadeOut'],
          //   dismiss: {
          //     duration: 3000,
          //     showIcon: true,
          //     onScreen: true
          //   }
          // });
        } else {
          setBtnIsLoading(false);
          errorNotification({
            title: t('Messages.linkingError'),
            message: t('Messages.tryAgain')
            // message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
          });
        }
      },
      (error) => {
        errorNotification({
          title: t('Messages.linkingError'),
          message: t('Messages.tryAgain')
          // message: res?.data?.message || 'البيانات المدخلة غير صحيحة'
        });
      }
    );
  };

  const syncSallaAuth = async () => {
    try {
      const res = await SyncSallaApi(token, sallaToken);
      if (res?.status === 200 && res?.data?.status === 1) {
        // setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Messages.addedSuccessfully'),
          message: res.data.message
        });
      } else {
        // setIsLoading(false);
        errorNotification({
          title: t('Messages.ErrorOccurred'),
          message: res?.data?.message
            ? res.data.message
            : t('Messages.TryAgainLater')
        });
      }
    } catch (error) {
      // setIsLoading(false);
      errorNotification({
        title: t('Messages.ErrorOccurred'),
        message: t('Messages.TryAgainLater')
      });
    }
  };
  const handleEditUser = (record) => {
    const found = stores.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedClient({ ...found });
      setModalOpened(true);
    }
  };
  const handleDeleteRequest = async (id) => {
    try {
      setIsLoading(true);
      const res = await deleteListIntegrationApi(token, id);
      if (res?.status === 200 && res?.data?.status === 1) {
        setIsLoading(false);
        setFetchCount((prevState) => prevState + 1);
        successNotification({
          title: t('Messages.addedSuccessfully'),
          message: t('Messages.DeletedSuccessfully')
        });
      } else {
        setIsLoading(false);
        errorNotification({
          title: t('Messages.ErrorOccurredDuringOperation'),
          message: res?.data?.message
            ? res.data.message
            : t('Messages.TryAgainLater')
        });
      }
    } catch (error) {
      setIsLoading(false);
      errorNotification({
        title: t('Messages.ErrorOccurred'),
        message: t('Messages.TryAgainLater')
      });
    }
  };
  const renderActions = (record) => {
    return (
      <div
        className="action-btns-wrap"
        style={{ display: 'flex', justifyContent: 'end' }}
      >
        <div className="edit-btn" onClick={() => handleEditUser(record)}>
          <EditIcon />
          {t('ShopInvoice.edit')}
        </div>
        <Popconfirm
          title={t('Messages.areyouSureToDelete')}
          onConfirm={() => handleDeleteRequest(record.id)}
          okText={t('Messages.yes')}
          cancelText={t('Messages.no')}
        >
          <Tooltip title={t('ShopInvoice.Delete')}>
            <div className="delete-btn">
              <DeleteIcon />
            </div>
          </Tooltip>
        </Popconfirm>
      </div>
    );
  };
  const renderActionsSync = (record) => {
    return (
      <div className="action-btns-wrap">
        {/* {record.name == 'salla' && sallaToken ? (
          <div className="sync-btn" onClick={() => syncSallaAuth()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              id="Uploaded to svgrepo.com"
              width="25px"
              height="25px"
              viewBox="0 0 32 32"
            >
              <path
                fill="#fff"
                class="feather_een"
                d="M24,10c0-4.418-3.582-8-8-8c-3.741,0-6.873,2.572-7.748,6.041C4.738,8.415,2,11.387,2,15  c0,3.684,2.848,6.697,6.461,6.973l14.014,0.02C22.222,25.347,19.418,28,16,28c-2.292,0-4.298-1.202-5.455-3H12.5  c0.276,0,0.5-0.224,0.5-0.5S12.776,24,12.5,24H9v3.5C9,27.776,9.224,28,9.5,28s0.5-0.224,0.5-0.5v-1.536  C11.366,27.799,13.536,29,16,29c3.975,0,7.22-3.095,7.475-7.006l0.629,0.001C27.369,21.939,30,19.279,30,16  C30,12.686,27.314,10,24,10z M23.777,21H9.525c0.257-3.351,3.059-6,6.475-6c2.293,0,4.301,1.201,5.457,3H19.5  c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5H23v-3.5c0-0.276-0.224-0.5-0.5-0.5S22,15.224,22,15.5v1.531  C20.634,15.198,18.463,14,16,14c-3.961,0-7.197,3.073-7.473,6.965c-2.299-0.177-4.377-1.607-5.148-3.799  c-1.391-3.954,1.227-7.731,4.979-8.13l0.693-0.074l0.118-0.467c0.708-2.806,2.986-5.038,5.853-5.428C19.315,2.482,23,5.819,23,10v1  h1c2.922,0,5.266,2.519,4.976,5.499C28.722,19.097,26.387,21,23.777,21z"
              />
            </svg>
            sync with salla
          </div>
        ) : (
          'لا يوجد'
        )} */}

        <Button
          className="sync-btn"
          onClick={() => handleAuthStore(record?.type)}
        >
          تفعيل الربط
        </Button>
      </div>
    );
  };

  return [
    // {
    //   title: 'الكود',
    //   dataIndex: 'id',
    //   // width: 62
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-index bold-font">
    //         <span># {record.id}</span>
    //       </div>
    //     );
    //   }
    // },
    {
      title: t('ShopInvoice.QuoteName'),
      dataIndex: 'name',
      // width: 192,
      render: (_, record) => {
        console.log(record);
        return (
          <div className="row-cell row-text">
            <span className="name-it-self">{record.name}</span>
          </div>
        );
      }
      // sorter: (a, b) => {
      //   let nameA = a.name.toUpperCase(); // ignore upper and lowercase
      //   let nameB = b.name.toUpperCase(); // ignore upper and lowercase
      //   if (nameA < nameB) {
      //     return -1;
      //   }
      //   if (nameA > nameB) {
      //     return 1;
      //   }

      //   // names must be equal
      //   return 0;
      // }
    },

    {
      title: t('ShopInvoice.Date'),
      dataIndex: 'date',
      // width: 192,
      render: (_, record) => {
        return <div className="row-cell row-text">{record?.date}</div>;
      }
    },
    {
      title: t('ShopInvoice.Reference'),
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">
            {renderActionsSync(record)}
          </div>
        );
      }
    }
    // {
    //   title: t('ShopInvoice.Settings'),
    //   dataIndex: 'actions',
    //   render: (_, record) => {
    //     return (
    //       <div className="row-cell row-actions">{renderActions(record)}</div>
    //     );
    //   }
    // }
  ];
};

export default TableColumns;
