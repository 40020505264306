import { Button } from 'antd';
import React, { useContext, useState } from 'react';
import OTPInput, { ResendOTP } from 'otp-input-react';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { store } from 'react-notifications-component';
import { useHistory } from 'react-router-dom';
import OtpResendCode from '../../apis/auth/otp-verification/OtpResendCode';
import OtpVerificationApi from '../../apis/auth/otp-verification/OtpVerificationApi';
import UserContext from '../../contexts/user-context/UserProvider';
import './otpVerification.scss';
// import { log } from 'console';

const OtpVerification = () => {
  const {
    setCurrentUser,
    setIsNewLoggedIn,
    msg
  } = useContext(UserContext);
  const history = useHistory();
  const [otp, setOtp] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const { setOtpValue, setPosReportModalOpened, phone } =
    useContext(UserContext);
  const [userObj, setUserObj] = useState(
    Cookies.get('currentUser') ? JSON.parse(Cookies.get('currentUser')) : {}
  );
  useEffect(() => {
    if (Cookies.get('currentUser')) {
      setUserObj(JSON.parse(Cookies.get('currentUser')));
    }
  }, []);

  const handleSubmitOtp = async () => {
    setIsLoading(true)
    try {
      const res = await OtpVerificationApi(userObj.token, phone, otp);
      if (res.status === 200 && res?.data?.data) {
        setCurrentUser({ ...res?.data?.data });
        if (
          res?.data?.data.subscriptionType === 1 &&
          res?.data?.data.role === 5
        ) {
          setIsLoading(false)
          setPosReportModalOpened(true);
          history.push('/pos');
        }
        setSuccessMessage(res?.data?.message);
        setErrorMessage('');
        setOtpValue(true);
        sessionStorage.setItem('otpValue', true);
        setTimeout(() => {
          setIsNewLoggedIn(false);
        }, 500);
        // history.push(routerLinks?.homePage);
        // store.addNotification({
        //   title: res?.data?.message,
        //   message: res.data?.message,
        //   type: 'success',
        //   insert: 'top',
        //   container: 'top-right',
        //   animationIn: ['animate__animated', 'animate__fadeIn'],
        //   animationOut: ['animate__animated', 'animate__fadeOut'],
        //   dismiss: {
        //     duration: 5000,
        //     showIcon: true,
        //     onScreen: true
        //   }
        // });
      } else {
        setIsLoading(false)
        setErrorMessage(res?.data?.message);
        setSuccessMessage('');
        // store.addNotification({
        //   title: res?.data?.message,
        //   message: res?.data?.message,
        //   type: 'danger',
        //   insert: 'top',
        //   container: 'top-right',
        //   animationIn: ['animate__animated', 'animate__fadeIn'],
        //   animationOut: ['animate__animated', 'animate__fadeOut'],
        //   dismiss: {
        //     duration: 5000,
        //     showIcon: true,
        //     onScreen: true
        //   }
        // });
      }
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    if (otp?.length == 6) {
      handleSubmitOtp()
    }
  }, [otp?.length == 6]);

  const handleReSendCode = async () => {
    try {
      const res = await OtpResendCode(userObj.token,phone);
      if (res?.data?.status) {
        store.addNotification({
          title: '',
          message: res?.data?.message,
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 2000,
            showIcon: true,
            onScreen: true
          }
        });
        setOtp('');
        setSuccessMessage('');
        setErrorMessage('');
      } else {
        store.addNotification({
          title: '',
          message: res?.data?.message,
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
            showIcon: true,
            onScreen: true
          }
        });
      }
    } catch (error) {}
  };

  const renderButton = (buttonProps) => {
    return (
      <Button
        {...buttonProps}
        className="resend-otp-btn"
        // onClick={() => handleReSendCode()}
      >
        {t('LoginPage.resendCode')}
      </Button>
    );
  };

  const renderTime = (remainingtime) => {
    return <span className="remainingtime"> 00 : {remainingtime} </span>;
  };

  return (
    <div className="otp-container">
      <div className="otp-card">
        <h2>{t('LoginPage.verifyPhoneNumber')}</h2>
        {/* <p>{t('LoginPage.enterCode')}</p> */}
        {msg && <h3>{msg}</h3>}
        {/* {msg && <div><h3 >000000 <WestIcon /> رجاء ادخال كود التحقق المرسل</h3> </div>} */}
        <OTPInput
          value={otp}
          onChange={setOtp}
          OTPLength={6}
          renderInput={(props) => <input {...props} placeholder="0" />}
          otpType="number"
          isInputNum={true}
          autoFocus
        />

        {errorMessage && (
          <h3 style={{ color: 'red' }} className="error">{`${errorMessage}, ${t(
            'LoginPage.enterCodeAgain'
          )}`}</h3>
        )}
        {successMessage && (
          <h3 style={{ color: 'green' }} className="error">
            {successMessage}{' '}
          </h3>
        )}

        <div className="otp-btn-group">
          {otp.length === 6? (
            <Button
              className="otp-btn"
              variant="contained"
              htmlType="submit"
              loading={isLoading}
              onClick={() => handleSubmitOtp()}
            >
              {t('LoginPage.send')}
            </Button>
          ) : (
            <Button
              type="submit"
              disabled
              className="otp-btn"
              variant="contained"
            >
              {t('LoginPage.send')}
            </Button>
          )}
        </div>
        
   { console.log("/////userObj/////",userObj)}
        <ResendOTP
          className="otp-input-btn"
          maxTime={60}
          renderTime={renderTime}
          renderButton={renderButton}
          onResendClick={() => handleReSendCode()}
        />
      </div>
    </div>
  );
};

export default OtpVerification;
