import axios from 'axios';

const addInvoicesSettingsApi = async (token, values) => {
  let formData = new FormData(); //formdata object
  formData.append('lang', values?.lang);
  formData.append('change_invoice_num', values?.change_invoice_num ? 1 : 0);
  formData.append('qrcode', values?.qrcode ? 1 : 0);
  formData.append('barcode', values?.barcode ? 1 : 0);
  formData.append('logo', values?.logo ? 1 : 0);
  formData.append('terms', values?.terms);
  formData.append('template', values?.template);
  formData.append('terms_text', values?.terms_text);

  try {
    const res = await axios.post('/invoice-setting/invoices', formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'applications/json',
        Authorization: 'Bearer ' + token,
        lang: 'ar'
      }
    });
    return res;
  } catch (error) {
    throw error;
  }
};

export default addInvoicesSettingsApi;
